// RO
const locale = {
    MENU: {
        DASHBOARD: "Dashboard",
        CREATE_REQUEST_BUTTON: "Creeaza cerere",
        SEARCH_BUTTON: "Cautare avansata",
        RESPONSE_TEMPLATES: "Template-uri de raspuns",
        TAX_CATEGORY: "Categorii de taxare",
        COMPLAIN_TYPE: "Tipologii de sesizare",
        ORGANIZATION: "Administrare organizatii",
        MY_ORGANIZATION: "Administrare organizație",
        ACCOUNTS: "Conturi secundare",
        BENEFICIARIES: "Beneficiari",
        MY_APPLICATIONS: "Cererile mele",
        STAFF_APPLICATIONS: "Registru cereri",
        NOTIFICATIONS: "Mesaje si notificari",
        RESPONSIBILITIES: "Nomenclator Responsabilități",
        MY_COMPLAINS: "Sesizarile mele",
        STAFF_COMPLAINS: "Registru sesizari",
        PAYMENTS: "Registru plati",
        DOCUMENTS: "Acte emise",
        EXTERNAL_APPS: "Aplicații",
        ATLAS: "Atlas teritorial",
        ARCHIVE: "Arhiva",
        HELP: "Ajutor",
        MANUAL: "Manual",
        FAQ: "Întrebări frecvente",
        ADMINISTRATOR: {
            HEADER: "Administrator",
            MANAGE: "Gestiune",
            FUNCTIONARY: "Functionar",
        },
        DOCUMENT_TEMPLATE: "Formulare tipizate",
        FORMS: "Interfețe formulare",
        APPLICATION_TYPE: "Tipologii de cereri",
        PROCESS_MANAGEMENT: "Management procese",
        PERMISSIONS: "Permisiuni",
        METADATA_MAPPING: "Mapare Metadate",
        GU: "Ghișeul Unic",
        STAFF_OWN_APPLICATIONS: "De semnat",
        DECISION_TARGET: "Hotărâri locații",
        DECISION_PURPOSE: "Hotărâri scopuri",
        DOCUMENT_SERIES_TYPE: "Tipologii de serie",
        PAYMENT_REGISTRY: "Registru plăți",
        ISSUED_DOCUMENT: "Acte emise",
        ISSUED_DOCUMENT_STAFF: "Registru acte emise",
        CONTROLS: "Registru de control",
        ORGANISATION_CONFIG: "Configurări organizație",
        SYSTEM_CONFIG: "Configurări sistem",
        INTERNAL_DOCUMENTS: "Documente interne",
        DOCUMENT_SERIES_ALLOCATION: "Documente externe",
        APPLICATIONS_DETAILS: "Detalii aplicații",
        CONTROL_TYPE: "Tipologii control",
        STAFF_SIGN_INTERNAL_DOCUMENTS: "De semnat",
        DASHBOARD_REPORTS: "Rapoarte",
        PURPOSE_LIST: "Scopuri hotărâri",
        TARGET_LIST: "Locații hotărâri"
    },
    GENERAL: {
        YES: "Da",
        NO: "Nu",
        CLOSE: "Închide",
        NEXT: "Înainte",
        PREV: "Înapoi",
        STATUS: "Status",
        ACTIVE: "Activ",
        INACTIVE: "Inactiv",
        CHECK: "Verifică",
        LOADING: "Se încarcă...",
        EXTEND: "Extinde",
        NOT_AVAILABLE: "Indisponibil",
        PREVIEW: "Previzualizare",
        BUTTON: {
            ADD: "Adaugă",
            CANCEL: "Renunță",
            SAVE: "Salvează",
            UPDATE: "Modifică",
            CONFIRM: "Confirmă",
            ERASE: "Șterge",
            MORE_FILTERS: "Alte filtre",
            OK: "Ok",
            SEND_CODE: "Trimite codul",
            SEND: "Trimite",
            CONTINUE: "Continuă",
            PUBLISH: "Publică",
            UPLOAD_FILE: "Alege fișier",
            COMPLETE: "Completează",
            UPLOAD_MAX_FILE_SIZE: "Încarcă document (max {0} MB)",
            DOWNLOAD: "Descarcă",
            EXTRACT: "Extrage selecție",
            SEARCH: 'Caută',
        },
        ACTIONS: {
            EDIT: "Editare",
            DELETE: "Ștergere",
            DELETE_MESSAGE:
                "Ești sigur că vrei să continui acțiunea de ștergere?",
            VIEW: "Vizualizare",
            DOWNLOAD: "Descarcă",
            SIGN: "Semnează",
            VIEW_ORIGINAL: "Vizualizare document",
            VIEW_STATUS: "Vizualizare status semnare",
            DO_UPDATE: "Actualizează",
            GO_TO_LINK: "Mergi la pagina instituției",
            COPY: "Copiază",
            SELECT_ALL: "Selectează toate",
            UN_SELECT_ALL: "Deselectează toate"
        },
        DATEPICKER: {
            FROM_DATE: "De la data",
            TO_DATE: "Până la data",
            SELECT_DATE: "Selectează",
            HELP: "Folosiți cursorul pentru a naviga prin datele din calendar",
        },
        PAGINATION: "Afișare rezultate de la {start} la {end}",
        CREATED_SUCCESS: "Salvat cu succes.",
        UPDATED_SUCCESS: "Salvat cu succes.",
        PUBLISHED_SUCCESS: "Publicat cu succes.",
        PUBLISHED_FAILED:
            "A apărut o eroare la publicarea layerului, vă rugăm reîncercați",
        REMOVE_SUCCESS: "Șters cu succes.",
        SELECT_PLACEHOLDER: "Alege",
        EXPIRED_SESSION: "Sesiunea a expirat",
        TABLE: {
            ACTIONS: "Acțiuni",
        },
        TABLE_ACTIONS_COLUMN: "Acțiuni",
        TABLE_EMPTY_TEXT: "Nu există înregistrări de afișat", // There are no records to show
        DAYS: "zile | zi | zile",
        AUTOCOMPLETE_NO_OPTIONS: "Nici o opțiune potrivită",
        AUTOCOMPLETE_LOAD_MORE: "Încarcă mai multe opțiuni...",
        DUAL_LIST_BOX: {
            SEARCH_PLACEHOLDER: "Caută",
            SEARCH_RESULTS: "Nu s-au găsit rezultate.",
        },
        CLICK_HERE: "click aici",
        CITIZEN: "Cetățean",
        STAFF: "Funcționar",
        SIGN: "Semnare",
        SYSTEM: "Sistem",
        ERROR_PAGE: {
            TITLE: "Oops...",
            TEXT: "A apărut o problemă...",
            SUB_TEXT: "eroare neașteptată",
        },
        SELECT_LOADING: "Încărcare",
        CURRENCY_LABEL: "RON",
        RECOMANDED_SCREEN:
            "Această aplicație nu este optimizată pentru telefoane mobile sau tablete. Pentru cea mai bună experiență de navigare și funcționare recomandăm folosirea versiunii web a aplicației.",
        MAINTENANCE: "Momentan sistemul este în mentenanță, iar unele funcționalități pot să nu funcționeze corect.",
        OR: "sau",
        SEARCH_OPTIONS: "Caută {0}",
        NO_RESULTS: "Nu există rezultate pentru ",
        COPY_PREFIX: "Anexa",
        MISSING_ADDRESS: "Adresă indisponibilă",
    },
    ALERT_MODAL: {
        ERROR: "Eroare",
        WARNING: "Atenție",
        SUCCESS: "Succes",
        INFO: "Info",
    },
    AUTH: {
        GENERAL: {
            OR: "sau",
            CANCEL_BUTTON: "Renunță",
            BACK_BUTTON: "Inapoi",
            PRIVACY: "Politica de Confidențialitate",
            LEGAL: "Termeni și Condiții",
        },
        LOGIN: {
            TITLE: "Bun venit!",
            DESC: "Introduceți email-ul și parola pentru a accesa platforma",
            NO_ACCOUNT: "Nu ai cont? Creează acum!",
            BUTTON: "Intră în cont",
            FORGOT_PASSWORD_LABEL: "Ai uitat parola ?",
            GOOGLE_BUTTON: "Intră in cont folosind Google",
            FACEBOOK_BUTTON: "Intră în cont folosind Facebook",
            HELP_TEXT:
                "* Aplicația permite crearea a două tipuri de conturi. Unul personal dedicat persoanelor fizice și juridice și unul profesional destinat birourilor de proiectare/planificare.",
        },
        FORGOT: {
            TITLE: "Resetare parolă?",
            DESC: "Introduceți adresa de email aferentă contului.",
            BUTTON: "Trimite email",
            SUCCESS: "Your account has been successfully reset.",
        },
        RESET: {
            TITLE: "Resetare parolă?",
            DESC: "Introduceți parola nouă",
            BUTTON: "Schimbă parola",
        },
        CHANGE_PASSWORD: {
            TITLE: "Schimbare parolă",
            DESC: "Introduceți parola nouă",
            BUTTON: "Schimbă parola",
        },
        SET: {
            TITLE: "Setare parolă",
            DESC: "Introduceți o parola",
            BUTTON: "Setează parola",
        },
        REGISTER: {
            TITLE: "Bun venit!",
            DESC: "Aplicația permite crearea a două tipuri de conturi. Unul personal dedicat persoanelor fizice/juridice, UAT și unul profesional destinat birourilor de proiectare/planificare.",
            HAS_ACCOUNT: "Ai cont deja? Conectează-te",
            GOOGLE_BUTTON: "Creează cont folosind Google",
            FACEBOOK_BUTTON: "Creează cont folosind Facebook",
            BUTTON: "Creează cont",
            ACCEPT_TERMS: "Accept {0}, {1}.",
            PRIVACY: "politica de confidențialitate",
            TOS: "termeni și condițiile",
            PASSWORD_HELP:
                "Minim 8 caractere, o majusculă, o minusculă, o cifră și un caracter special",
            PROFESSIONAL_TITLE: "Creează cont Profesional nou",
            PROFESSIONAL_DESC:
                "Contul de tip profesional se adresează persoanelor juridice - birouri / societăți / p.f.a. / b.i.a care activează în domeniul proiectării în (arhitectură, urbansim, inginerie, s.a.) și este destinat gestionării/depunerii de cereri pentru unul sau mai mulți beneficiari - permite subconturi",
            PERSONAL_TITLE: "Creează cont Personal nou",
            PERSONAL_DESC:
                "Contul de tip personal se adresează persoanelor fizice / juridice, administrațiilor publice și este destinat depunerii de cereri pentru/în numele persoanelor fizice / juridice / primăriilor",
            BUTTON_PERSONAL: "Creează cont Personal",
            BUTTON_PROFESSIONAL: "Creează cont Profesional",
        },
        INPUT: {
            EMAIL: "E-mail",
            PASSWORD: "Parolă",
            CONFIRM_PASSWORD: "Confirmă parola",
            NEW_PASSWORD: "Parolă noua",
            OLD_PASSWORD: "Parolă veche",
            PHONE: "Număr telefon",
            VALIDATION_CODE_PLACEHOLDER: "Cod validare",
        },
        VERIFY_EMAIL: {
            TITLE: "Confirmare e-mail",
            MESSAGE_SENT:
                "S-a trimis un link de activare la adresa de e-mail {0}. Verifică e-mailul iar dupa confirmare continuă procesul de validare a contului. Uneori link-ul de activare poate ajunge în spam, te rugăm să verifici și folderul de spam.",
            RESEND_CONFIRMATION_MESSAGE:
                "Nu ai primit e-mail de confirmare? {0}.",
            RESEND_LINK: "Retrimite link-ul",
            TOAST_MESSAGE: "Emailul de confirmare trimis cu succes",
        },
        VERIFY_SMS: {
            TITLE: "Validarea contului",
            MESSAGE:
                "Din motive de securitate Ghiseul Unic are nevoie sa iti verifice contul prin SMS. Introdu numarul de telefon si urmeaza pasii pentru validare.",
            MESSAGE_SENT:
                "S-a trimis un SMS la numarul de telefon {0}. Adauga codul primit si valideaza contul.",
            BUTTON_SUBMIT: "Trimite cod",
            BUTTON_VALIDATE: "Valideaza contul",
            INPUT_PLACEHOLDER: "Cod validare",
            MESSAGE_NOT_RECEIVED: "Nu ai primit codul? {0}",
            RESEND_LINK: "Retrimite mesajul",
            CHANGED_VALIDATION_METHOD_LINK: "schimba metoda de validare",
        },
        INVALID_LINK: {
            TITLE: "Link de activare invalid",
            MESSAGE:
                "Link-ul de activare este invalid, a expirat sau a fost deja folosit. Te rugăm să îți verifici contul sau să reiei procesul de înregistrare.",
            REGISTER_MESSAGE: "Mergi la pagina de {0}.",
            REGISTER_LINK: "înregistrare",
        },
        VALIDATION: {
            INVALID: "{{name}} nu este valid",
            REQUIRED: "Acest câmp este obligatoriu",
            EMAIL: "Adresa de e-mail nu este valabilă",
            INVALID_EMAIL: "Adresa de e-mail nu este validă.",
            MIN_LENGTH: "Lungimea minimă a {{name}} este {{min}}",
            AGREEMENT_REQUIRED: "Acceptarea termenilor și condițiilor este obligatoriu",
            NOT_FOUND: "The requested {{name}} is not found",
            INVALID_LOGIN: "Date de autentificare incorecte. Te rugăm să încerci din nou.",
            REQUIRED_FIELD: "Acest câmp este obligatoriu",
            CONFIRM_PASSWORD: "Parola nu este identică",
            MIN_LENGTH_FIELD: "Câmpul trebuie să aibă minim {min} caractere",
            MAX_LENGTH_FIELD: "Câmpul trebuie să aibă maxim {max} caractere",
            INVALID_FIELD: "Câmpul nu este valid",
            STRONG_PASSWORD: "Parolă invalidă",
            INVALID_PHONE: "Numarul de telefon nu este valid",
            NUMERIC_FIELD: "Acest câmp conține doar valori numerice",
            PHONE_FIELD: "Acest câmp conține doar valori numerice",
            INVALID_URC: "CUI invalid",
            INVALID_PNC: "CNP invalid",
            POSTAL_CODE_MAX_LENGTH_FIELD: "Codul poștal trebuie să conțină 6 cifre",
            SMS_CODE_LENGTH: "Codul de verificare trebuie să conțină 6 cifre",
            IBAN: "Cod IBAN invalid.",
            INTEGER: "Valoarea câmpului nu este un număr întreg valid",
            INVALID_DATE: "Dată invalidă",
            ALPHABETICAL: "Valoarea câmpului trebuie să conțină doar litere și spațiu",
            NO_UPPER: "Acest câmp conține doar minuscule",
            ALPHABETICAL_SPECIAL_CHARS: "Valoarea câmpului trebuie să conțină doar litere, spațiu, apostrof și -",
            URL: "Link-ul introdus nu e valid",
            FULL_URL: "Link-ul introdus nu e valid. Prefixul \"https\" e obligatoriu.",
            ARCGIS_FEATURE_SERVICE_URL: "Link-ul introdus nu e un valid Arcgis Feature Service",
            INVALID_EMAIL_DOMAIN: "Va rugăm să folosiți o altă adresa de email de pe alt domeniu",
        },
    },
    LAYOUT: {
        HEADER: {
            SEARCH_PLACEHOLDER: "Cauta cerere, aviz sau alt document",
        },
    },
    LOGOUT: {
        CONFIRMATION_MESSAGE:
            "Esti sigur ca doresti terminarea sesiunii de lucru?",
    },
    RESET_PASSWORD: {
        CONFIRMATION_MESSAGE: "Eşti sigur că doreşti să resetezi parola?",
        SUCCESSFULLY_SENT_MESSAGE:
            "S-a trimis un link de resetare a parolei pe adresa ta de e-mail indicată.",
        SUCCESSFULLY_MESSAGE: "Parola dvs. a fost schimbată cu succes",
    },
    HOME: {
        WELCOME_TEXT: "Bine ați venit în aplicația Ghișeul Unic.",
    },
    PROFILE: {
        HEADER: "Contul meu",
        HEADER_CITIZEN: "Contul meu Personal",
        HEADER_LEGAL: "Contul meu Profesional",
        LOGOUT_BUTTON_TEXT: "Delogare",
        UPDATE_BUTTON: "Actualizeaza datele",
        VIDEO_ENROLL_BUTTON: "Înrolare video",
        SECTIONS: {
            ACCOUNT_DETAILS: {
                HEADER: "Detalii cont",
            },
            PERSONAL_DETAILS: {
                HEADER: "Detalii personale",
            },
            PERSONAL_DATAS: {
                HEADER: "Date personale",
            },
            COMPANY_DATAS: {
                HEADER: "Date firmă",
            },
            RESPONSIBILITIES: {
                HEADER: "Responsabilități",
            },
            IDENTITY_CARD: {
                HEADER: "Date carte de identitate",
            },
        },
        OPTIONS: {
            MY_PROFILE: {
                NAME: "Profilul meu",
                DESCRIPTION: "Vezi detalii profil",
            },
            RESET_PASSWORD: {
                NAME: "Resetare Parola",
                DESCRIPTION: "Aceseaza formularul de resetare",
            },
            NOTIFICATIONS_MANAGEMENT: {
                NAME: "Management Notificari",
                DESCRIPTION: "Gestioneaza tipul și frecvența notificarilor",
            },
        },
        FIELDS: {
            PERSONAL_NUMERIC_CODE: "CNP",
            LAST_NAME: "Nume",
            FIRST_NAME: "Prenume",
            COUNTY: "Județ",
            LOCALITY: "Municipiu/Oraș/Comună",
            VILLAGE: "Localitate",
            SECTOR: "Sector",
            STREET: "Strada",
            POSTAL_CODE: "Cod Poștal",
            STREET_NUMBER: "Număr",
            STAIRCASE: "Scara",
            BLOCK: "Bloc",
            FLOOR: "Etaj",
            APARTMENT: "Apartament",
            EMAIL: "E-mail",
            PHONE: "Telefon",
            PASSPORT_NUMBER: "Pașaport/Carte de identitate",
            COUNTRY: "Țara",
            CITY: "Oraș",
            ADDRESS: "Adresa",
            UNIQUE_REGISTRATION_CODE: "CIF sau CUI",
            REGISTRATION_NUMBER: "Număr înregistrare Oficiul Registrul Comerțului",
            COMPANY_NAME: "Denumire entitate",
            IBAN: "Cont IBAN",
            BANK: "Deschis la",
            LICENSE: "Arhitect - număr TNA",
            STATUS: "Status",
            IDENTIFICATION_NUMBER: "Număr legitimaţie",
            ORGANISATION: "Instituție",
            FUNCTIONS: "Funcție",
            DEPARTMENTS: "Departament",
            USER_TYPE_NAME: "Tip utilizator",
            ID_CARD_SERIAL: "Serie CI",
            ID_CARD_NUMBER: "Număr CI",
            ID_CARD_EXPIRATION_DATE: "Data expirării",
            ID_CARD_ISSUER: "Eliberat de",
        },
        PLACEHOLDERS: {
            COUNTY: "Județ",
            LOCALITY: "Localitate",
            VILLAGE: "Sat",
            COUNTRY: "Țara",
            STATUS: "Status",
        },
        ENUMS: {
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
        },
        MESSAGES: {
            ID_CARD_EXPIRED: "Cartea de identitate a expirat!",
            STAFF_ENROLLMENT_PROCESSING_TITLE: "Înrolare video în procesare...",
            STAFF_ENROLLMENT_SUCCESS_TITLE:
                "Funcționarul a fost înrolat cu succes",
            STAFF_ENROLLMENT_REDIRECT_TEXT:
                "Vei fi redirecționat în câteva secunde la profilul tău. Dacă nu ești redirecționat automat te rugăm dă {0}",
            STAFF_ENROLLMENT_FAILED_TITLE:
                "A apărut o eroare la procesul de înrolare, te rugăm încearcă din nou.",
        },
        ENROLLED_BADGE_LABEL: "Înrolat",
    },
    SET_PROFILE_DATA: {
        UPDATE_PROFILE_MODAL: {
            HEADER: 'Actualizare importantă de date!',
            TEXT: 'Ca urmare a dezvoltării unor facilități noi în aplicație, vă adresăm rugămintea de a actualiza datele de profil. Vă mulțumim!',
            GO_TO_PROFILE: 'Mergi la Profilul meu',
        },
        WELCOME_MODAL: {
            BRAND: {
                NAME: "Ghișeul unic",
                ORGANISATION: "Consiliul Județean Cluj",
            },
            HEADER: {
                TOP_P: "Bun venit în platforma Ghișeul Unic!",
                BOTTOM_P:
                    "Completează datele profilului tău pentru a putea customiza experiența conform nevoilor tale.",
                BOTTOM_P_PERSON: "Setează cont Personal",
                BOTTOM_P_LEGAL: "Setează cont Profesional",
            },
            OPTIONS: {
                PERSON: "Setează profil Personal",
                LEGAL: "Setează profil Profesional",
            },
        },
        CITIZEN: {
            ROMANIAN: "Cetățean romȃn",
            FOREIGNER: "Cetățean străin",
        },
        FORM_BUTTONS: {
            CHANGE_TYPE: "Schimbă tipul profilului",
            SAVE: "Salvează datele",
        },
        LEGAL_FORM: {
            COMPANY: "Detalii Firma",
            LEGAL_REPRESENTATIVE: "Detalii reprezentant Legal",
        },
        PERSON: {
            PNC: "CNP",
            LAST_NAME: "Nume",
            FIRST_NAME: "Prenume",
            COUNTY: "Judet",
            LOCALITY: "Municipiu/Oraș/Comună",
            VILLAGE: "Localitate",
            SECTOR: "Sector",
            STREET: "Strada",
            POSTAL_CODE: "Cod Postal",
            NUMBER: "Numar",
            STAIRCASE: "Scara",
            BLOCK: "Bloc",
            FLOOR: "Etaj",
            APARTMENT: "Apartament",
            EMAIL: "E-mail",
            PHONE: "Telefon",
        },
        FOREIGNER: {
            PNC: "Pasaport/Carte de identitate",
            LAST_NAME: "Nume",
            FIRST_NAME: "Prenume",
            COUNTRY: "Tara",
            CITY: "Oras",
            ADDRESS: "Adresa",
            EMAIL: "E-mail",
            PHONE: "Telefon",
        },
        LEGAL: {
            URC: "CUI",
            COMPANY_NAME: "Denumire",
            COUNTY: "Judet",
            LOCALITY: "Localitate",
            STREET: "Strada",
            POSTAL_CODE: "Cod Postal",
            NUMBER: "Numar",
            STAIRCASE: "Scara",
            BLOCK: "Bloc",
            FLOOR: "Etaj",
            APARTMENT: "Apartament",
            LAST_NAME: "Nume",
            FIRST_NAME: "Prenume",
            PNC: "CNP",
            LICENSE: "Arhitect - număr TNA",
            EMAIL: "E-mail",
            PHONE: "Telefon",
        },
        ERRORS: {
            LOCALITIES: "Eroare la generarea listei de localitati!",
            VILLAGES: "Eroare la generarea listei de sate!",
            UPDATE: "Eroare la modificarea informațiilor despre profil!",
            CREATE: "Eroare la crearea profil utilizator!",
        },
        SUCCESS_MODAL: {
            TITLE: "Bun venit {0}!",
            TEXT: "Acum ai posibilitatea de a încarca și transmite documentele necesare procesului de autorizare și de a-ți gestiona documentele.",
            CONFIRM_BUTTON: "Hai să începem!",
        },
        UPDATED_MESSAGE: "Profilul a fost actualizat cu succes!",
        GDPR: {
            AGREE_CONDITION: "Sunt de acord cu prelucrarea datelor cu caracter personal",
            CONDITIONS: "În temeiul Regulamentului (UE) nr. 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (Regulament general privind protecția datelor) și al Legii nr.190/2018 Consiliul Județean Cluj prelucrează date cu caracter personal, cu asigurarea securității și confidențialității acestora.”"
        }
    },
    NOTIFICATIONS: {
        BUTTON_TEXT: "Aplică modificările",
        HEADER: {
            TOP: "Management notificări",
            COL_1: "Tipul notificării",
            COL_2: "Email",
            COL_3: "Sms",
            COL_4: "În aplicație",
        },
        SECTION_TITLE: {
            ACCOUNT: "Cont",
            APPLICATIONS: "Cereri",
            DOCUMENTS: "Acte",
            PAYMENTS: "Plați",
            ALERTS: "Alerte",
            OTHERS: "Altele",
            COMPLAINS: "Sesizări",
            COMMENTS: "Mesaje",
            INTERNAL_DOCUMENTS: "Documente Interne",
            CONTROLS: "Controale"
        },
        FREQUENCY: {
            DAILY: "Zilnic",
            WEEKLY: "Saptamanal",
            MONTHLY: "Lunar",
        },
        ITEMS: {
            ACCOUNT_DATA_CHANGED: "Se modifica datele contului",
            ACCOUNT_TYPE_CHANGED: "Se modifica tipul contului",
            NEW_APPLICATION: "Se primește o cerere noua",
            APPLICATION_CHANGED: "Se modifică o cerere",
            APPLICATION_STATUS_CHANGED: "Se schimbă statusul unei cereri",
            APPLICATION_SENT: "Se trimite o cerere",
            DOCUMENT_CREATED: "Se emite un act nou",
            DOCUMENT_CANCELLED: "Se anuleaza un act",
            NEW_PAYMENT: "Se face o plata noua",
            PAYMENT_APPROVED: "Se aproba o plata",
            TAX_DEADLINE_EXPIRES: "Expira un termen de plata",
            RESPONSE_DEADLINE_EXPIRES: "Expira un termen de raspuns",
            MESSAGE_SENT: "Se trimite mesaj.",
            NEW_COMPLAIN: "Se primește o sesizare nouă",
            COMPLAIN_STATUS_CHANGED: "Se schimbă statusul unei sesizări",
            NEW_COMMENT: "Se primește un mesaj nou",
            INTEREST_ZONE_INTERSECTED: "Se primesc noutăți în zonele de interes",
            INTERNAL_DOCUMENT_STATUS_CHANGED: "Se schimbă statusul unui document intern",
            CONTROLS_STATUS_CHANGED: "Se schimbă statusul unui control"
        },
        ERRORS: {
            NOTIFICATIONS: "Eroare la generarea listei de notificari!",
            UPDATE: "Eroare la modificarea notificării!",
        },
        SUCCESS: {
            UPDATE: "Modificarea notificării s-a realizat cu succes.",
        },
        TEMPLATES: {
            TITLE: "Template-uri notificari",
            TABLE: {
                ID: "ID",
                TITLE: "Titlu",
                TYPE: "Tip",
                LANGUAGE: "Limba",
                ACTIONS: "Actiuni",
                EDIT: "Editeaza",
                SEARCH_TEMPLATE: "Cauta template",
            },
            EDIT: {
                PAGE_TITLE: "Editeaza template",
                LANGUAGE: "Limba",
                RO: "Ro",
                EN: "En",
                HU: "Hu",
                NAME: "Denumire template mesaj",
                SUBJECT: "Subiect",
                TITLE: "Titlu",
                CHANNEL: "Canal de livrare",
                SMS: "SMS",
                EMAIL: "E-mail",
                IN_APP: "In-App",
                ACCEPTED_PLACEHOLDERS: "Placeholdere acceptate",
                CANCEL: "Renunta",
                SAVE: "Salveaza",
                MAX_CHARS: "Max: {chars} caractere.",
                REMAINING_CHARS: "Au mai ramas: {chars}",
            },
        },
    },
    NOTIFICATIONS_IN_APP: {
        TITLE: "Notificări",
        MESSAGE: {
            INPUT_CODE: "Cod intrare",
            BENEFICIARY: "Beneficiar",
        },
        LOCALE: {
            FUTURE: "în",
            PAST: "acum",
            SECOND: "căteva secunde",
            SECONDS: "secunde",
            MINUTE: "un minut",
            MINUTES: "minute",
            HOUR: "o oră",
            HOURS: "ore",
        },
        NOW: "Acum",
    },
    BENEFICIARY: {
        BENEFICIARY_DETAILS: {
            HEADER: "Detalii beneficiar",
            ADD_BTN: "Adaugă beneficiar",
            REMOVE_BTN: "Sterge beneficiar",
            PERSON: {
                HEADER: "Tip persoană",
                INDIVIDUAL: "Persoană fizică",
                LEGAL: "Persoană juridică",
            },
            CITIZENSHIP: {
                HEADER: "Cetățenie",
                ROMANIAN: "Cetățean romȃn",
                FOREIGNER: "Cetățean străin",
            },
            FOOTER_CHECKBOX: "Beneficiarul este persoana de contact",
            FORM: {
                AUTHORIZATION_CODE: "TNA",
                SUBSIDIARY: "Filiala",
            },
            ADDRESS: "Adresă",
            CONTACT: "Contact",
            OFFICE_DETAILS_HEADER: "DATE PRIVIND SEDIUL SOCIAL",
            PERSON_DETAILS_HEADER: "DATE REPREZENTANT LEGAL"
        },
        APPLICANT_DETAILS: {
            HEADER: "Detalii solicitant",
            FOOTER_CHECKBOX: "Solicitantul este persoana de contact",
        },
        SINGLE: "Total {total} beneficiar",
        MULTIPLE: "Total {total} beneficiari",
        LIST_HEADER: "Lista beneficiarilor",
        MODAL: {
            ADD_HEADER: "Adaugă un beneficiar",
            EDIT_HEADER: "Editează un beneficiar",
        },
        CARD: {
            PERSONAL_NUMERIC_CODE: "CNP",
            ADDRESS: "Adresă domiciliu",
            CONTACT: "Contact",
            CITIZENSHIP: "Cetătenie",
            ADDRESS_COUNTY: "Judetul",
            ADDRESS_STREET: "Str.",
            ADDRESS_NUMBER: "nr.",
            ADDRESS_STAIRCASE: "scara",
            ADDRESS_APARTMENT: "ap.",
        },
    },
    ERRORS: {
        DEFAULT_ERROR_MESSAGE: "A apărut o eroare, vă rugăm reîncercați",
        AUTH_REQUIRED: "Este nevoie de autorizare.",
        AUTH_INVALID: "Autentificare eșuată.",
        AUTH_INVALID_ATTEMPTS_REMAINING:
            "Autentificare eșuată. {0} încercări rămase.",
        REGISTRATION_EMAIL_ALREADY_EXISTS: "Adresa de e-mail există deja.",
        REGISTRATION_INVALID_PASSWORD: "Parolă invalidă",
        REGISTRATION_INVALID: "Înregistrare invalidă.",
        FACEBOOK_EMAIL_NOT_RECEIVED:
            "A apărut o eroare, nu s-a putut obține adresa dvs. de e-mail. Verificați setările Facebook și încercați din nou!",
        PASSWORD_EXPIRED: "Parola dvs. a expirat și trebuie schimbată.",
        AUTH_FAILED: "Autentificare eșuată.",
        AUTH_FAILED_ATTEMPTS_REMAINING:
            "Autentificare eșuată. {0} încercări rămase.",
        RESET_PASSWORD_ALREADY_USED:
            "Parola trebuie să fie diferită de cea curentă.",
        FORBIDDEN: "Ne pare rău, nu aveți drepturi necesare ca să continuați.",
        TOKEN_INVALID: "Token invalid",
        DUPLICATE_FUNCTION_NAME: "Numele funcției adăugate deja există!",
        CATEGORY_NOT_EMPTY: "Categoria nu poate fi ștearsă!",
        NAME_NOT_UNIQUE: "Numele nu este unic.",
        ERROR_PAGE_MESSAGE: "OOPS! Something went wrong here",
        AUTH_ACCOUNT_NOT_FOUND: "Credențialele sunt introduse greșit!",
        PARENT_MUST_BE_ADMIN_OF_LEGAL_ENTITY:
            "Nu aveți permisiunea de a adăuga sau edita un cont!",
        USER_ALREADY_EXISTS: "Acest utilizator este deja existent!",
        TAX_ALREADY_EXISTS: "Această taxă este deja existentă!",
        VERIFICATION_SERVICE_UNAVAILABLE: "Codul introdus nu este valid!",
        CANNOT_DELETE_ORGANISATION:
            "Această organizație nu poate fi ștearsă fiindcă există utilizatori asociați",
        ORGANISATION_ALREADY_EXISTS: "Această organizație există deja",
        DOCUMENT_SERIES_ALREADY_EXISTS: "Această serie este deja existentă!",
        DOCUMENT_SERIES_NOT_EXIST: "Nu există serie definită!",
        DOCUMENT_SERIES_NOT_ACTIVE: "Seria nu este activă!",
        RESPONSIBILITY_ALREADY_EXISTS:
            "Această responsabilitate este deja existentă!",
        INDEX_CANNOT_BE_LOWER_THAN_CURRENT_VALUE:
            "Valoarea indexului nu poate fi mai mică decât valoarea curentă!",
        CONFLICT: "Înregistrare duplicat",
        PAYMENT_ALREADY_PROCESSED: "Plata a fost deja procesată!",
        AUTH_INVALID_CONFIRMATION_VALIDATIONS:
            "Solicitarea de resetare parolă a expirat!",
        CERTSIGN_CONFIRMING_SIGNING_FAILED: "Codul introdus nu este valid!",
        ATLAS_NO_SEARCH_RESULT: "Adresa nu a fost găsită!",
        DECISION_TARGET_CANNOT_BE_DELETED:"Această locație nu poate fi ștearsă fiindcă este asociată unei hotărâri",
        DECISION_PURPOSE_CANNOT_BE_DELETED:"Aceast scop nu poate fi ștears fiindcă este asociat unei hotărâri",
        DECISION_PURPOSE_ALREADY_EXISTS: "Aceast scop este deja existent!",
        DECISION_TARGET_ALREADY_EXISTS: "Această locație este deja existentă!",
        DOCUMENT_SERIES_TYPE_CANNOT_BE_DELETED:"Această tipologie nu poate fi ștearsă fiindcă este asociată unei serii",
        DOCUMENT_SERIES_TYPE_ALREADY_EXISTS:"Această tipologie este deja existentă!",
        DUPLICATE_COMPLAIN_TYPE_NAME:"Numele tipului de sesizare introdus deja există!",
        DUPLICATE_RESPONSE_TEMPLATE_NAME:"Numele templateului introdus deja există!",
        INVALID_EMAIL: "Adresa de email nu este valabilă",
        INVALID_CERTSIGN_ENROLLMENT:"A apărut o eroare la procesul de semnare, lipsă înrolare.",
        REQUEST_ALREADY_CONFIRMED: "Cererea a fost deja semnată",
        INVALID_TOKEN: "Ne pare rău, a apărut o eroare. Incercați din nou!",
        SOURCE_FILE_ID_MISSING_ERROR:"Ne pare rău, încărcarea fișierului nu a fost cu succes. Incercați din nou!",
        UAT_ALREADY_ASSIGNED: "UAT-ul selectat este asignat la alt funcționar!",
        ERROR_VALIDATING_LAYER_CREATION_REQUEST:"A apărut o eroare, vă rugăm reîncercați",
        NOT_OK: "A apărut o eroare, verificați dacă documentele sunt semnate și încercați din nou.",
        ARCHIVE_CONTENT_INVALID_FILES_BY_NAME:"A apărut o eroare, verificați numele pachetului de documente și încercați din nou.",
        INVALID_FILE:"A apărut o eroare, extensia pachetului de documente trebuie fie .zip",
        AUTH_FAILED_ACCOUNT_LOCKED:"Autentificarea a eșuat. Contul este blocat. Incercați din nou peste {0} minute",
        AUTH_DISABLED:"Eroare de autentificare, contul este blocat sau inactiv.",
        URBANISM_REQULATION_ZONE_NOT_UNIQUE:"Denumirea zonei (per UAT și Scop) nu este unică.",
        AUTH_FAILED_PASSWORD_EXPIRED:"Parola dvs. a expirat și trebuie schimbată.",
        WORKFLOW_EXISTS: "Un proces cu acest nume există deja",
        MISSING_SIGN_PLACEHOLDER: "A apărut o eroare la procesul de semnare, vă rugăm reîncercați.",
        INVALID_TARGET_FOR_SIGN_PLACEHOLDER:"A apărut o eroare la procesul de semnare, vă rugăm reîncercați.",
        MISMATCH_TARGET_FILES_AND_META: "A apărut o eroare la procesul de semnare, vă rugăm reîncercați.",
        CERTSIGN_SIGNING_ID_NOT_GENERATED: "A apărut o eroare la procesul de semnare, vă rugăm reîncercați.",
        PERMISSIONS_ARE_EQUAL_TO_CURRENT_RESPONSIBILITY: "Permisiunile salvate sunt aceleași cu cele inițiale.",
        TOO_MANY_FILES_OR_FILE_SIZE_EXCEEDED: "Numărul maxim de fișiere a fost atins sau documentul depășește limita maxim admisă.",
        CERTSIGN_INTERNAL_ERROR: "A apărut o eroare la procesul de semnare, vă rugăm reîncercați.",
        ERROR_INIT_SIGNING: "A apărut o eroare la inițierea procesul de semnare, vă rugăm reîncercați.",
        ERROR_DOCUMENT_GENERATION: "A apărut o eroare, te rugăm încearcă mai târziu.",
        CANNOT_DELETE_FUNCTION_ASSOCIATED_USERS_EXIST: "A apărut o eroare la ștergere, funcția are utilizatori asociați.",
        CANNOT_DELETE_FUNCTION_ASSOCIATED_DEPARTMENTS_EXIST: "A apărut o eroare la ștergere, funcția este asignată la un departament.",
        APPLICATION_NOT_SUBMITTED: "A apărut o eroare la procesul de depunere, vă rugăm reîncercați.",
        ERROR_VALIDATING_RU_SCOPE_AND_UAT_ALREADY_EXISTS_FOR_SIMPLE_LAYER:  "Scop RU cu UAT-ul selectat deja există.",
        ERROR_VALIDATING_CONTAINER_COMPONENT_MISMATCHED_RU_SCOPE: "Scop RU nu este aceleași cu cel al container-ului.",
        ERROR_VALIDATING_RU_SCOPE_ALREADY_EXISTS_FOR_CONTAIANERS:"Scop RU există deja în lista de containere.",
        PDF_INVALID: "Fișierul nu are o semnătura validă!",
        PDF_IS_ENCRYPTED: "Fișierul nu are o semnătura validă sau este criptat!",
        ERROR_VALIDATING_PUBLISHED_LAYER_WAS_ALREADY_PUBLISHED: "Layerul selectat a fost deja publicat. ",
        DUPLICATE_DEPARTMENT_NAME: "Există un departament cu acest nume",
        CAN_NOT_USE_SYSTEM_FUNCTIONS: "Există o funcție de sistem cu acest nume",
        FUNCTION_NAME_ALREADY_EXIST: "Există o funcție cu acest nume",
        INVALID_PDF_ERROR: "Semnatura este invalidă, vă rugăm reîncercați folosind o semnatură digitală validă.",
        PDF_READING_ERROR: "Fișierul nu a putut fi citit, vă rugăm reîncercați.",
        EMPTY_RESPONSIBILITIES: "Funcția nu are responsabilități atribuite.",
        REJECT_FORM_STAFF: "Acestei tipologii de cerere îi lipsește interfața formular sau formularul tipizat pentru respingere",
        ERROR_VALIDATING_TARGET_CONTAINER_IS_SYSTEM_LAYER: "Eroare la publicare layer, layerul sursă este de tip sistem.",
        AUTH_SERVICE_UNAVAILABLE: "A apărut o eroare, te rugăm încearcă mai târziu.",
        ERROR_VALIDATING_LAYER_NAME_ALREADY_EXISTS: "Denumirea layerului este deja utilizată.",
        THE_REQUEST_IS_NOT_VALID: "A apărut o eroare, te rugăm încearcă mai târziu.",
        EMPTY_TARGET_FILES: "Nu există document generat, cererea nu se poate semna",
        ERROR_VALIDATING_ARCGIS_IMPORTED_ITEM_DOES_NOT_EXISTS: "Layerul nu este accesibil sau layerul nu există.",
        ERROR_VALIDATING_ARCGIS_IMPORTED_ITEM_INVALID: "Identificatorul layerului nu este valid.",
        FILE_NOT_EXISTS: "Fișierul pentru care se încearcă ștergerea, nu există!",
        ERROR_MISSING_DOCUMENT_REJECTED_SERIES: "Seria pentru acest document nu a fost setată!",
        INVALID_FORM_VALUE: "Valoarea introdusă în formular nu este validă!",
        TOO_MANY_INTEREST_AREAS: 'S-a atins numărul maxim de zone de interes ce pot fi definite.',
        FILE_NOT_EXISTS: 'Fișierul pentru care se încearcă ștergerea, nu există!',
        APPLICATION_TYPE_EXISTS: "Există deja o tipologie de cerere cu această denumire",
        ERROR_DOWNLOAD_COMPRESSION: "A apărut o eroare la descărcarea fișierelor, vă rugăm reîncercați.",
        APPLICATION_REQUEST_DENIED: "Nu aveți suficiente permisiuni pentru a vizualiza cererea",
        APPLICATION_ALREADY_SUBMITTED: 'Cererea a fost depusă, nu poate fi salvat ca si Draft.',
        CANNOT_INIT_PAYMENT: "Plata nu poate fi inițiată."
    },
    REQUESTS: {
        BUILDING: {
            DETAILS: "Detalii imobil",
            LAND: "Teren",
            LAND_CONSTRUCTIONS: "Teren + Constructii",
            COUNTY: "Judet",
            LOCALITY: "Localitate",
            VILLAGE: "Sat",
            SECTOR: "Sector",
            STREET: "Strada",
            POSTAL_CODE: "Cod postal",
            NUMBER: "Numar",
            STAIRCASE: "Scara",
            BLOCK: "Bloc",
            FLOOR: "Etaj",
            APARTMENT: "Apartament",
            LAND_AREA: "Suprafata terenului",
            OTHER_IDENTIFICATION: "Alta identificare",
            CADASTRAL_NUMBER: "Nr. cadastral",
            ADD_CADASTRAL_NUMBER: "Adauga nr. cadastral",
            REMOVE_CADASTRAL_NUMBER: "Sterge nr. cadastral",
        },
    },
    VALIDATION: {
        REQUIRED_FIELD: "Acest câmp este obligatoriu!",
        NUMERIC_FIELD: "Acest câmp conține doar valori numerice!",
        EMAIL_FIELD: "Adresa de e-mail este invalida",
        PHONE_FIELD: "Numărul de telefon este invalid",
        UPLOADED_FILE_SIZE: "Dimensiunea fișierul este prea mare",
        UPLOADED_FILE_SIZE_NAME: "Dimensiunea fișierul {name} este prea mare",
        UPLOADED_FILE_TYPE: "Tipul fișierului nu este suportat",
        MAX_UPLOADED_FILES: "Maxim {max} fișiere",
        PDF_FIELD: "Extensia fișierului selectat trebuie să fie .pdf .",
    },
    PLACEHOLDERS: {
        MP: "mp",
    },
    INSTITUTION: {
        MAIN_HEADER: "Organizație selectată",
        PAGE_HEADER: "Organizații",
        LIST: {
            TITLE: "Lista organizațiilor",
        },
        FIELDS: {
            NAME: "Denumire",
            STATUS: "Status",
            COUNTY: "Județ",
            LOCALITY: "Localitate",
            TYPE: "Tip",
            UAT: "UAT",
            DESCRIPTION: "Descriere",
            IS_TEST: "Organizație de test",
            STAFF_USERS: "Funcționar",
            ORGANISATION: "Organizație",
            LOGO: "Logo",
            EXTERNAL_LINK: "Link extern",
            IS_CITIZEN_VISIBLE: "Vizibilă pe dashboard-ul cetățeanului"
        },
        PLACEHOLDERS: {
            NAME: "Nume",
            STATUS: "Stare",
            DESCRIPTION: "Introduceți descrierea organizației",
            IS_TEST: "Organizație de test",
        },
        ENUMS: {
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
            IS_TEST: {
                YES: "Da",
                NO: "Nu",
            },
        },
        MAIN_PAGE: {
            SEARCH: {
                PLACEHOLDER: "Caută organizație",
                ERASE_BUTTON: "Șterge",
            },
            TABLE_HEADER: {
                NAME: "Nume",
                DESCRIPTION: "Descriere",
                STATUS: "Stare",
                IS_TEST: "Organizație de test",
                ACTIONS: "Acțiuni",
            },
            TOOLTIPS: {
                EDIT: "Editare",
                DELETE: "Ștergere",
                FUNCTIONS: "Funcții",
                DEPARTMENTS: "Departamente",
                USERS: "Utilizatori",
                ACCOUNTS: "Conturi colectoare",
                TAXES: "Taxe",
                RESPONSIBILITIES: "Responsabilități",
            },
            STATUS_TYPE: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
        },
        TABS: {
            DETAILS: "Detalii",
            FUNCTIONS: "Funcții",
            DEPARTMENTS: "Departamente",
            TAXES: "Taxe",
            USERS: "Utilizatori",
            ACCOUNTS: "Conturi BT iPay",
            DOCUMENT_SERIES: "Serii documente",
            RESONSIBILITIES: "Responsabilitați",
            DECISIONS: "Hotărâri",
            URBANISM_REGULATIONS: "Reglementări",
        },
        ADD_EDIT_FORM: {
            NAME: "Nume organizație",
            TYPE: "Tip",
            DESCRIPTION: "Descriere",
            COUNTY: "Județ",
            CITY: "Localitate",
            STATUS_FIELD: "Status",
            TEST_INST: "Organizație de test",
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
            TYPES: {
                COUNTY_COUNCIL: "Consiliul Județean",
                CITY_HALL: "Primărie",
                SERVICES: "Servicii",
            },
            PLACEHOLDER: "Introduceți descrierea organizației",
        },
        ADD_MODAL: {
            HEADER: "Adaugă organizație",
        },
        BUTTON: {
            ADD: "Adaugă",
            CANCEL: "Renunță",
            SAVE: "Salvează",
            UPDATE: "Modifică",
        },
        FUNCTIONS: {
            SEARCH: {
                PLACEHOLDER: "Caută functie",
                ERASE_BUTTON: "Șterge",
            },
            TABLE_HEADER: {
                NAME: "Nume Funcție",
                DESCRIPTION: "Descriere",
                ACTIONS: "Acțiuni",
            },
            TOOLTIPS: {
                EDIT: "Editare",
                DELETE: "Ștergere",
                USERS: "Utilizatori",
                RESPOSABILITIES: "Asociere responsabilități",
            },
        },
        ERRORS: {
            ORG: "Eroare la generarea listei de instituții!",
            SINGLE_ORG: "Eroare la generarea datelor pentru instituție!",
            FUNCTIONS: "Eroare la generarea listei de funcții!",
            UPDATE_ORG: "Eroare la modificarea instituției.",
            ADD_ORG: "Eroare la adăugarea instituției.",
            DELETE_ORG:
                "Această instituție nu poate fi ștearsă fiindcă există utilizatori asociați",
        },
        SUCCESS: {
            UPDATE_ORG: "Instituția a fost modificată cu succes.",
            ADD_ORG: "Instituția a fost adaugată cu succes.",
            DELETE_ORG: "Instituția a fost ștearsă cu succes.",
        },
        ORG_SELECTION_FORM: {
            HEADER: "Selectare organizație activă",
        },
    },
    DEPARTMENTS: {
        PAGE_HEADER: "Departamente",
        LIST: {
            TITLE: "Lista departamentelor",
        },
        FIELDS: {
            NAME: "Denumire",
            STATUS: "Stare",
            DESCRIPTION: "Descriere",
        },
        PLACEHOLDERS: {
            NAME: "Nume",
            STATUS: "Stare",
            DESCRIPTION: "Descriere",
        },
        ENUMS: {
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
        },
        TABLE_HEADER: {
            NAME: "Nume Departament/Serviciu",
            DESCRIPTION: "Descriere",
            STATUS: "Stare",
            ACTIONS: "Acțiuni",
        },
        TOOLTIPS: {
            FUNCTIONS: "Asignare Funcții",
            USERS: "Utilizatori",
        },
        ADD_EDIT_MODAL: {
            HEADER: {
                ADD: "Adaugă departament",
                EDIT: "Modifică departament",
            },
            NAME: "Nume departament",
            DESCRIPTION: "Descriere",
            STATUS: "Stare",
            STATUS_TYPE: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
            TYPES: {
                COUNTY_COUNCIL: "Consiliul Județean",
                CITY_HALL: "Primărie",
                SERVICES: "Servicii",
            },
            PLACEHOLDER: "Introduceți descrierea departamentului",
        },
        ASSOCIATED_FCT_MODAL: {
            HEADER: "Asignare Funcții",
            ERRORS: {
                LIST: "Eroare la generarea listei de funcții asociate!",
                ADD_FUNCTION: "Eroare la adaugarea funcției la departament.",
            },
        },
        ERRORS: {
            DEP_LIST: "Eroare la generarea listei de departamente!",
            ADD: "Eroare la adăugarea departamentului.",
            UPDATE: "Eroare la modificarea departamentului.",
        },
        SUCCESS: {
            ADD: "Departamentul a fost adaugat cu succes.",
            UPDATE: "Departamentul a fost modificat cu succes.",
        },
        BUTTON: {
            ADD_DEPARTMENT: "Adaugă departament",
        },
    },
    FUNCTIONS: {
        PAGE_HEADER: "Funcții",
        LIST: {
            TITLE: "Lista funcțiilor",
            SYSTEM_FUNCTIONS:
                "Funcțiile - {systemFunctions} - sunt create automat de sistem.",
        },
        FIELDS: {
            NAME: "Denumire",
            DESCRIPTION: "Descriere",
        },
        PLACEHOLDERS: {
            NAME: "Nume",
            DESCRIPTION: "Descriere",
        },
        ASSOCIATED_RESPOSABILITIES_MODAL: {
            HEADER: "Asignare Responsabilități",
            BODY: {
                TOP: "Funcția",
            },
        },
        ADD_EDIT_FORM: {
            HEADER_ADD: "Adaugă funcție",
            HEADER_EDIT: "Editează funcție",
            NAME: "Denumire",
            DESCRIPTION: "Descriere",
            PLACEHOLDER: "Introduceți descrierea funcției",
        },
        SUCCESS: {
            ADD: "Funcția a fost adaugată cu succes.",
            UPDATE: "Funcția a fost modificată cu succes.",
            DELETE: "Funcția a fost ștearsă cu succes.",
        },
        BUTTON: {
            ADD_FUNCTION: "Adaugă funcție",
        },
    },
    TERRITORIAL_UNIT_ALLOCATION: {
        HEADER: "Asignare UAT",
        FIELDS: {
            TU_NAME: "Denumire",
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: "UAT",
        },
        PLACEHOLDERS: {
            TU_NAME: "Nume",
        },
        MODAL: {
            HEADER: "Adaugă UAT",
            OBS: "UAT-urile asignate nu apar în listă",
        },
        BUTTON: {
            ADD_UAT: "Adaugă UAT",
        },
        TABLE_EMPTY_TEXT: "Nu sunt UAT-uri asignate pentru acest utilizator.",
    },
    ADMIN_USERS_MODAL: {
        HEADER: "Listă de conturi utilizatori",
        PLACEHOLDER: "Caută utilizator",
        TABLE_HEADER: {
            LAST_NAME: "Nume",
            FIRST_NAME: "Prenume",
            FUNCTION: "Funcție",
            STATUS: "Stare",
            STATUS_ACTIVE: "Activ",
            STATUS_INACTIVE: "Inactiv",
        },
        FUNCTIONS: {
            ERRORS: {
                LIST: "Eroare la generarea listei de utilizatori pentru o funcție!",
            },
        },
        DEPARTMENTS: {
            ERRORS: {
                LIST: "Eroare la generarea listei de utilizatori pentru un departament!",
            },
        },
    },
    ACCOUNTS: {
        BUTTONS: {
            ADD_ACCOUNT: "Adaugă cont",
            CANCEL: "Renunță",
            INVITE: "Invită prin email",
            RESEND_LINK: "Retrimite link",
            SAVE: "Salvează",
        },
        ENUMS: {
            ROLES: {
                ADMINISTRATOR: "Administrator",
                EDITOR: "Editor",
            },
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
                PENDING: "Trimis",
            },
        },
        FIELDS: {
            CREATED_BY_NAME: "Creat de",
            EMAIL: "E-mail",
            FIRST_NAME: "Prenume",
            LAST_NAME: "Nume",
            PHONE: "Telefon",
            STATUS: "Status",
            ROLE: "Rol",
            USER_NAME: "Nume",
        },
        LABELS: {
            CREATED_BY: "Creat de",
            USER: "Utilizator",
        },
        LIST: {
            ACTIONS: "Acțiuni",
            EDIT: "Editează",
            HEADER: "Conturi conectate",
        },
        MODALS: {
            EDIT_ACCOUNT: "Editează contul",
            ADD_NEW_ACCOUNT: "Adaugă un cont nou",
        },
        NOTIFIERS: {
            EDITED_SUCCESS: "Contul a fost actualizat!",
            INVITE_TEXT:
                "O invitație a fost trimisă cu succes pe adresa de e-mail <strong>{email}</strong>. După validarea contului aceasta va apărea în cadrul organizației",
            INVITE_TITLE: "Invitație trimisă",
            RESEND_SUCCESS: "Linkul a fost retrimis!",
        },
        PLACEHOLDERS: {
            CHOOSE: "Alege",
            ROL: "Rol",
            SEARCH_NAME: "Cauta numele",
            STATUS: "Status",
        },
        TITLE: "Administrare conturi",
    },
    COLLECTOR_ACCOUNTS: {
        PAGE_HEADER: "Conturi BT iPay",
        LIST: {
            TITLE: "Lista conturilor BT iPay",
        },
        FIELDS: {
            NAME: "Denumire cont",
            BANK: "Banca",
            USERNAME: "Username BT iPay",
            PASSWORD: "Parola BT iPay",
            STATUS: "Stare",
        },
        PLACEHOLDERS: {
            NAME: "Denumire cont",
            BANK: "Banca",
            USERNAME: "Username BT iPay",
            PASSWORD: "Parola BT iPay",
            STATUS: "Stare",
        },
        ENUMS: {
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
        },
        TABLE_HEADER: {
            NAME: "Denumire cont",
            BANK: "Banca",
            USERNAME: "Username BT iPay",
            PASSWORD: "Parola BT iPay",
            STATUS: "Stare",
            ACTIONS: "Acțiuni",
        },
        STATUS_TYPE: {
            ACTIVE: "Activ",
            INACTIVE: "Inactiv",
        },
        TOOLTIPS: {
            EDIT: "Editare",
            DELETE: "Ștergere",
        },
        MANAGE_ACCOUNT_MODAL: {
            HEADER: {
                EDIT: "Editeaza Cont BT iPay",
                ADD: "Adauga Cont BT iPay",
            },
            FORM: {
                NAME: "Nume",
                BANK: "Banca",
                USERNAME: "Username BT iPay",
                PASSWORD: "Parola BT iPay",
                STATUS: "Stare",
            },
        },
        CONFIRMATION_MESSAGE: "Esti sigur ca vrei sa stergi contul colector?",
        SUCCESS: {
            DELETE: "Contul Colector a fost sters cu succes.",
            ADD: "Contul colector a fost adaugat cu succes.",
            UPDATE: "Contul colector a fost modificat cu succes.",
        },
        BUTTON: {
            ADD_COLLECTOR_ACCOUNT: "Adaugă cont",
        },
    },
    BENEFICIARIES: {
        BUTTONS: {
            ADD_ACCOUNT: "Adaugă beneficiar",
            CANCEL: "Renunță",
            SAVE: "Salvează",
        },
        FIELDS: {
            BENEFICIARY: "Beneficiar",
            CREATED_BY_NAME: "Creat de",
            EMAIL: "E-mail",
            FIRST_NAME: "Prenume",
            LAST_NAME: "Nume",
            ID_CARD_SERIAL: "Serie CI",
            ID_CARD_NUMBER: "Număr CI",
            PHONE: "Telefon",
            STATUS: "Status",
            ROLE: "Rol",
            USER_TYPE: "Tip persoană",
            PERSONAL_NUMERIC_CODE: "CNP",
            USER_NAME: "Nume",
            LOCALITY: "Localitate",
            PAYMENT: "Plata",
            STATUS: "Status",
            LOCALITY: "Municipiu / Oraș / Comună",
            EMPLACEMENT_LOCALITY: "Localitate",
            FIRST_NAME: "Prenume",
            LAST_NAME: "Nume",
            EMAIL: "E-mail",
            PHONE: "Telefon",
            USER_TYPE_NAME: "Tip persoană",
            PERSONAL_NUMERIC_CODE: "CNP",
            COUNTY: "Județ",
            VILLAGE: "Localitate",
            SECTOR: "Sector",
            STREET: "Strada",
            STREET_NUMBER: "Număr stradă",
            POSTAL_CODE: "Cod poștal",
            STAIRCASE: "Scara",
            BLOCK: "Bloc",
            FLOOR: "Etaj",
            APARTMENT: "Apartament",
            USE_PROFILE_DATA: "Folosește date profil",
            PASSPORT_NUMBER: "Nr. pașaport",
            CITY: "Oraș",
            ADDRESS: "Adresă",
            LICENSE: "Licență",
            COMPANY_NAME: "Denumire entitate",
            IBAN: "Cont IBAN",
            BANK: "Deschis la",
            UNIQUE_REGISTRATION_CODE: "CIF sau CUI",
            REGISTRATION_NUMBER: "Număr înregistrare Oficiul Registrul Comerțului",
        },
        LABELS: {
            CREATED_BY: "Creat de",
            USER: "Utilizator",
        },
        LIST: {
            ACTIONS: "Acțiuni",
            EDIT: "Editează",
            HEADER: "Beneficiari",
            DELETE: "Șterge"
        },
        MODALS: {
            EDIT_ACCOUNT: "Editează beneficiar",
            ADD_NEW_ACCOUNT: "Adaugă un beneficiar nou",
        },
        NOTIFIERS: {
            ADDED_SUCCESS: "Beneficiarul a fost adăugat!",
            EDITED_SUCCESS: "Beneficiarul a fost actualizat!",
            DELETE_SUCCESS: "Beneficiarul a fost șters cu succes!"
        },
        PLACEHOLDERS: {
            CHOOSE: "Alege",
            ROL: "Rol",
            SEARCH_NAME: "Cauta numele",
            STATUS: "Status",
        },
        TITLE: "Administrare beneficiari",
        SUCCESS: {
            DELETE: "Beneficiarul a fost șters cu succes!"
        }
    },
    TAX_CATEGORY: {
        LIST: {
            TITLE: "Categorii de taxare",
        },
        FILTER: {
            TITLE: "Categorii de taxare",
        },
        ADD_BUTTON: "Categorie noua",
        FIELDS: {
            NAME: "Denumire",
            STATUS: "Status",
        },
        FORM: {
            EDIT_TITLE: "Editeaza categorie",
            ADD_TITLE: "Categorie noua",
        },
        ENUMS: {
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
        },
        PLACEHOLDERS: {
            NAME: "Cauta dupa denumire",
            STATUS: "Status",
        },
    },
    COMPLAIN_TYPE: {
        LIST: {
            TITLE: "Tipologii de sesizări",
        },
        ADD_BUTTON: "Tipologie nouă",
        FIELDS: {
            NAME: "Denumire",
            STATUS: "Status",
            COMPLAIN_DOCUMENT_SERIES_TYPE: "Tip serie de document",
            TENANT_ID: "Organizație",
        },
        FORM: {
            EDIT_TITLE: "Editează tip sesizare",
            ADD_TITLE: "Tip sesizare nouă",
        },
        ENUMS: {
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
        },
        PLACEHOLDERS: {
            NAME: "Caută după denumire",
            STATUS: "Status",
        },
    },
    COMPLAIN: {
        LIST: {
            TITLE: "Sesizări",
            TABLE_HEADER: "Istoric sesizări",
            ADD_BUTTON: "Sesizare nouă",
            NO_ASSIGNEE: "Nealocată",
        },
        STAFF_LIST: {
            TITLE: "Sesizări",
            TABLE_HEADER: "Sesizări",
            ADD_BUTTON: "Control nou",
        },
        FIELDS: {
            COMPLAIN_TYPE: "Tip sesizare",
            LOCALITY: "Localitate",
            ADDRESS: "Adresă",
            ADDRESS_DETAILS: "Detalii adiționale locație",
            DESCRIPTION: "Descrierea sesizării",
            FIRST_NAME: "Prenume",
            LAST_NAME: "Nume",
            EMAIL: "E-mail",
            PHONE: "Telefon",
            STATUS: "Status",
            REPORTED_BY: "Creat de",
            REPORTED_FOR: "Persoana de contact",
            IDENTIFICATION_NUMBER: "Cod intrare",
            CREATED_DATE: "Data",
            USE_PROFILE_DATA: "Folosește datele profilului meu",
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: "UAT",
            MESSAGE_COMMENT: 'Mesajul tău',
            RESPONSE_TEMPLATES: 'Template-uri de răspuns',
            DISMISSED_COMMENT: 'Mesaj template',
            UAT: 'Municipiu / Oraș / Comună',
            ASSIGNED_TO: "Soluționat de",
        },
        FORM: {
            TITLE: "Formular sesizare nouă",
            TITLE_CLASSIFY: 'Clasare sesizare',
            TITLE_RESOLVED: 'Soluționare sesizare',
            COMPLAIN_DETAIL_SECTION_TITLE: "Detalii sesizare",
            USER_DETAIL_SECTION_TITLE: "Detalii persoană contact",
            ATLAS_PREVIEW_SECTION_TITLE: "Locație",
            DOCUMENTS_SECTION_TITLE: "Încarcă documente / fotografii",
            VIEW_DOCUMENTS_SECTION_TITLE: "Vizualizare documente / fotografii",
            UPLOAD_DOCUMENT_BUTTON: "Încarcă document",
            SUBMIT_BUTTON: "Trimite sesizarea",
            VIEW_GOOGLE_MAPS_BUTTON: "Vizualizare in Google Maps",
            CLASSIFY_BUTTON: "Clasare",
            RESOLVED_BUTTON: "Soluționează",
            EMPTY_DOCUMENT_LABEL: "Nu există documente atașate",
            RESOLVE_BUTTON: "Trimite răspuns - Soluţionată",
            RESPONSE: 'Răspuns sesizare',
            PREVIEW_TITLE: "Previzualizare notificare",
            NOTIFICATION_TEMPLATE_TEXT: "Sesizarea de tipul '{type}' având numarul unic de înregistrare {number} creată de {user}, a fost clasată în Ghișeul Unic, motivul fiind:"
        },
        TABS: {
            COMPLAIN: "Sesizare",
            DISCUSSIONS: "Activități",
        },
        PLACEHOLDERS: {
            DESCRIPTION: "Mesajul tău",
            STATUS: "Status",
            REPORTED_BY: "Creat de",
            REPORTED_FOR: "Persoana de contact",
            LOCALITY: "Localitate",
            IDENTIFICATION_NUMBER: "Caută după cod",
            ASSIGNED_TO: "Soluționat de"
        },
        ENUMS: {
            STATUS: {
                NEW: "Inregistrată",
                PENDING: "In procesare",
                RESOLVED: "Soluţionată",
                REJECTED: "Respinsă",
                DISMISSED: "Clasată",
            },
        },
        NOTIFIERS: {
            CREATED_SUCCESS_TITLE: "Sesizare trimisă",
            CREATED_SUCCESS_TEXT:
                "Sesizarea cu numarul <strong>{number}</strong> a fost trimisă cu succes.</br> Dupa o analiza si verificare vei fi notificat in legatura cu rezolvarea acesteia.",
            CLASSIFICATION_MESSAGE:
                "Sunteţi sigur că doriţi să clasaţi sesizarea?",
            CLASSIFICATION_SUCCESS_TEXT: "Sesizarea a fost clasată.",
            RESOLVE_MESSAGE:
                "Sunteţi sigur că doriţi să soluţionaţi sesizarea?",
            RESOLVE_SUCCESS_TEXT: "Sesizarea a fost soluţionată.",
            LOCATION_TITLE: "Lipsă locaţie!",
            LOCATION_TEXT:
                "Te rog alege locaţia sesizării pe hartă pentru a depune sesizarea.",
        },

    },
    FORM_FIELDS: {
        SAVE_BTN: "Salvează",
        INVITE_BTN: "Invită prin email",
        CANCEL_BTN: "Renunţă",
        FIRST_NAME: "Prenume",
        LAST_NAME: "Nume",
        FULL_NAME: "Nume Prenume",
        EMAIL: "Email",
        PHONE: "Telefon",
        DEPARTMENT: "Departament",
        ORGANISATION: "Instituţie",
        ROLE: "Rol",
        TAG_ID: "Număr legitimaţie",
        TAG_RELEASED_BY: "Legitimaţie eliberată de",
        EXTERNAL_ENROLLMENT_ID: "External ID (Enrollment)",
        FUNCTION: "Funcţie",
        RESPONSIBILITIES: "Responsabilităţi",
        PERMISSIONS: "Permisiuni",
        STATUS: "Status",
        IS_ORGANISATION_ADMIN: "Administrator organizație",
        MESSAGES: {
            ACCOUNT_CREATED: "Contul a fost creat",
            ACCOUNT_UPDATED: "Contul a fost modificat",
            ACCOUNT_NOT_CREATED: "A aparut o eroare la crearea contului",
            ACCOUNT_NOT_UPDATED: "A aparut o eroare la modificarea contului",
        },
        ASSIGNED_TERRITORIAL_ADMINISTRATIVE_UNIT: "UAT-uri asignate",
    },
    FUNCTIONARY: {
        LIST: {
            TITLE: "Lista funcționarilor",
        },
        ENUMS: {
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
                PENDING: "În așteptare",
            },
        },
        FIELDS: {
            FIRST_NAME: "Prenume",
            LAST_NAME: "Nume",
            EMAIL: "Email",
            STATUS: "Status",
            FULL_NAME: "Nume Prenume",
            DEPARTMENTS: "Departament",
            FUNCTIONS: "Funcție",
            ACCOUNT_TYPE: "Tip cont",
            ADMIN: "Administrator",
            STAFF: "Funcționar",
        },
        PLACEHOLDERS: {
            EMAIL: "Email",
            STATUS: "Status",
            FULL_NAME: "Nume Prenume",
            DEPARTMENTS: "Departament",
            FUNCTIONS: "Funcție",
            LAST_NAME: "Nume",
        },
        HEADER_LIST: "Administrare conturi funcționari",
        ADD_BTN: "Adaugă cont",
        ADD_ACCOUNT_HEADER: "Adaugă cont nou",
        EDIT_ACCOUNT_HEADER: "Modificare cont",
        TABLE_HEADER: {
            ACTIONS: "Acțiuni",
        },
        DELETE_ACCOUNT_MESSAGE:
            "Ești sigur că vrei să ștergi contul de funcționar?",
        DELETED_ACCOUNT_SUCCESS: "Contul a fost șters",
        RESET_PASS_SUCCESS:
            "S-a trimis un link de resetare a parolei pe adresa de e-mail <strong>{email}</strong>.",
        CHANGE_STATUS_SUCCESS: "Statusul contului a fost modificat",
        DENY_DELETE_ACCOUNT: "Contul nu poate fi șters",
        CHANGE_ACCOUNT_STATUS:
            "<p>Ești sigur că vrei să schimbi statusul contului?<p><small>Status curent: <b>{status}</b></small>",
        RESET_PASSWORD: "Resetare parolă",
        RESET_PASSWORD_CONFIRM: "Ești sigur că vrei să resetezi parola?",
        CHANGE_ACCOUNT_STATUS_ACTION: "Activare/dezactivare cont",
        DENY_RESET_PASSWORD:
            "Parola nu poate fi resetată pentru un cont inactiv",
        PERMISSIONS_TOOLTIP: "Permisiuni",
        PERMISSIONS: {
            HEADER: "Listă permisiuni",
            FILTER_PLACEHOLDER: "Caută după denumire",
            PERMISSION_NAME_LABEL: "Denumire",
        },
        TOOLTIPS: {
            TERRITORIAL_UNIT_ALLOCATION: "Adăugare/Ștergere UAT-uri",
        },
    },
    RESPONSE_TEMPLATES: {
        LIST: {
            TITLE: "Template-uri de raspuns",
            ACTIONS: "Actiuni",
        },
        ADD_BUTTON: "Adauga template",
        FIELDS: {
            TITLE: "Titlu",
            CATEGORY: "Categorie",
            MESSAGE: "Mesaj template",
        },
        FORM: {
            EDIT_TITLE: "Editeaza template",
            ADD_TITLE: "Adauga template",
        },
        ENUMS: {
            CATEGORY: {
                COMPLAINS: "Sesizari",
                APPLICATIONS: "Cereri",
                NOTICE: "Avize",
            },
        },
        PLACEHOLDERS: {
            TITLE: "Cauta dupa titlu",
            CATEGORY: "Categorie",
            MESSAGE: "Mesaj template",
        },
        CATEGORY: {
            COMPLAINS: "sesizare",
            APPLICATIONS: "cerere",
            NOTICE: "aviz",
        },
    },
    TAXES: {
        BUTTONS: {
            ADD_TAX: "Adaugă taxă",
        },
        FIELDS: {
            NAME: "Denumire",
            ENTRY_DATE: "Data intrării în vigoare",
            JUSTIFICATION: "HCL/NR/DATA",
            CATEGORY: "Categorie",
            FIXED_VALUE: "Valoare fixă",
            PERCENTAGE_VALUE: "Valoare procentuală",
            PRICE_CALCULATION_MODEL: "Tipul de calcul",
            START: "Început",
            END: "Final",
            VALUE: "Valoare",
        },
        LABELS: {},
        LIST: {
            ACTIONS: "Acțiuni",
            EDIT: "Editează",
            HEADER: "Lista taxelor",
        },
        MODALS: {
            ADD_TITLE: "Adaugă taxă",
            EDIT_TITLE: "Editează taxă",
            HCL: "HCL / Altă justificare",
            ADD: "Adaugă",
            SAVE: "Salvează",
            CANCEL: "Renunță",
        },
        NOTIFIERS: {
            SAVED: "Taxa a fost salvată",
            EDITED: "Taxa a fost actualizată",
            INVALID_RANGES: "Intervalele sau valorile nu sunt valide",
        },
        PLACEHOLDERS: {
            NAME: "Denumire",
            CATEGORY: "Categorie",
        },
        ENUMS: {
            PRICE_CALCULATION_MODEL: {
                FIXED: "Fix",
                RANGE: "Intervale",
                MIXED: "Mix",
                PERCENTAGE: "Procentual",
            },
        },
    },
    DOCUMENT_SERIES: {
        BUTTONS: {
            ADD_SERIES: "Adaugă serie",
        },
        FIELDS: {
            NAME: "Denumire",
            SEPARATOR: "Separator",
            DESCRIPTION: "Descriere",
            PREFIX: "Prefix",
            REJECTED_PREFIX: "Prefix adresă răspuns",
            CURRENT_INDEX: "Index curent",
            YEARLY_RESET: "Resetare anuală",
            ACTIVE: "Activ",
            SERIES_TYPE: "Tip serie",
            REJECTED_INDEX: "Index adresă răspuns",
        },
        LIST: {
            EDIT: "Editează",
            HEADER: "Lista seriilor de documente",
        },
        MODALS: {
            ADD_TITLE: "Adaugă serie document",
            EDIT_TITLE: "Editează serie document",
            ADD: "Adaugă",
            SAVE: "Salvează",
            CANCEL: "Renunță",
        },
        NOTIFIERS: {
            SAVED: "Seria de documente a fost salvată",
            EDITED: "Seria de documente a fost actualizată",
            LOCATION_ALREADY_ADDED: "Locatia este deja adaugată",
        },
        PLACEHOLDERS: {
            NAME: "Denumire",
        },
    },
    ORG_RESPONSIBILITIES: {
        BUTTONS: {
            ADD_RESPONSIBILITY: "Adaugă responsabilitate",
        },
        LIST: {
            TITLE: "Responsabilități",
        },
        FILTER: {
            TITLE: "Listă Responsabilități",
        },
        FIELDS: {
            RESPONSIBILITY_NAME: "Denumire",
            RESPONSIBILITIES: "Responsabilități",
        },
        PLACEHOLDERS: {
            RESPONSIBILITY_NAME: "Caută dupa denumire",
            RESPONSIBILITIES: "Responsabilități",
        },
        FORM: {
            ADD_TITLE: "Adaugă responsabilitate",
            EDIT_TITLE: "Modifică responsabilitate",
            SELECTED_RESPONSIBILITY: "Responsabilitate selectată",
            PERMISSIONS: "Permisiuni",
        },
        CLARIFICATION: {
            ADD: "Responsabilitatea se poate adăuga dacă există responsabilitate și permisiuni selectate.",
            EDIT: "Responsabilitatea se poate modifica doar dacă există permisiuni selectate.",
        },
    },
    RESPONSIBILITY: {
        FILTER: {
            TITLE: "Responsabilități",
        },
        FIELDS: {
            NAME: "Denumire",
            DESCRIPTION: "Descriere",
            CREATED_DATE: "Data inserării",
            UPDATED_DATE: "Ultima actualizare",
            ACTIONS: "Acțiuni",
        },
        PLACEHOLDERS: {
            NAME: "Caută dupa denumire",
            PERMISSIONS: "Caută Permisiuni",
        },
        FORM: {
            ASSOCIATED_PERMISSIONS: "Permisiuni asociate",
            EDIT_TITLE: "Editează responsabilitate",
            ADD_TITLE: "Adaugă responsabilitate",
        },
    },
    APPLICATION: {
        LIST: {
            TITLE_STAFF: "Registru cereri",
            TITLE: "Cereri",
            TABLE_HEADER: "Listă cereri",
            ADD_BUTTON: "Cerere nouă",
            REFRESH_BUTTON: "Reîncarcă",
            ADD_DOCUMENT_BUTTON: "Document",
            DOCUMENT_CREATED_HEADER: "Documente create",
            DOCUMENT_RECEIVED_HEADER: "Documente primite",
            PAYMENTS_HEADER: 'Plăți înregistrate',
            NO_ASSIGNEE: "Nealocată",
            SIGN_DOCUMENT_BULK: "Semnează documente",
            CONFIRM_DELETE_TEXT: "Ești sigur că vrei să ștergi această cerere? Cererile șterse nu mai pot fi accesate!",
            REFRESH_DOCUMENT_BUTTON: "Reîncarcă",
            REFRESH_PAYMENTS_BUTTON: "Reîncarcă",
        },
        FIELDS: {
            APPLICATION_TYPE_NAME: "Selectează tipul cererii",
            APPLICATION_TYPE_NAME_COLUMN: "Tip cerere",
            APPLICATION_TYPE_PURPOSE: "Selectează scopul cererii",
            TERRITORIAL_ADMINISTRATIVE_UNIT: "UAT",
            TERRITORIAL_ADMINISTRATIVE_UNIT_TYPE: "Tip UAT",
            PAYMENT: "Plata",
            STATUS: "Status",
            LOCALITY: "Municipiu / Oraș / Comună",
            EMPLACEMENT_LOCALITY: "Localitate",
            FIRST_NAME: "Prenume",
            LAST_NAME: "Nume",
            ID_CARD_SERIAL: "Serie CI",
            ID_CARD_NUMBER: "Număr CI",
            EMAIL: "E-mail",
            PHONE: "Telefon",
            IDENTIFICATION_NUMBER: "Cod intrare",
            CREATED_DATE: "Dată creare",
            APPLICATION_DATE: "Dată intrare",
            LAST_REVIEW_DATE: "Ultima completare",
            RESPONSE_DUE_IN: "Timp rămas",
            CADASTRAL_NUMBER: "Număr cadastru",
            USER_TYPE_NAME: "Tip persoană",
            PERSONAL_NUMERIC_CODE: "CNP",
            COUNTY: "Județ",
            VILLAGE: "Localitate",
            SECTOR: "Sector",
            STREET: "Strada",
            STREET_NUMBER: "Număr stradă",
            POSTAL_CODE: "Cod poștal",
            STAIRCASE: "Scara",
            BLOCK: "Bloc",
            FLOOR: "Etaj",
            APARTMENT: "Apartament",
            REAL_ESTATE_TYPE: "Tip amplasament",
            AUTHORISATION_CODE: "TNA",
            SUBSIDIARY: "Filiala",
            LAND_AREA: "Suprafața terenului",
            OTHER_IDENTIFICATION: "Altă identificare",
            CREATED_BY: "Creat de",
            FILE_TYPE: "Tip document",
            RESOLVED_DATE: "Data soluționării",
            PIN: "Cod confirmare",
            USE_PROFILE_DATA: "Folosește date profil",
            PASSPORT_NUMBER: "Nr. pașaport",
            CITY: "Oraș",
            ADDRESS: "Adresă",
            LICENSE: "Licență",
            COMPANY_NAME: "Denumire entitate",
            IBAN: "Cont IBAN",
            BANK: "Deschis la",
            UNIQUE_REGISTRATION_CODE: "CIF sau CUI",
            REGISTRATION_NUMBER: "Număr înregistrare Oficiul Registrul Comerțului",
            FILENAME: "Nume document",
            IS_CONTACT_PERSON: "Solicitantul este persoana de contact",
            TITLE: "Titlu",
            MESSAGE: "Mesaj",
            COMMENTS: "Comentarii",
            CITIZEN_REVIEW: "Vă rugăm să enumerați modificările aduse cererii",
            ASSIGNED_TO: "Responsabil",
            CLARIFICATION_COMMENT: "Mențiuni",
            MESSAGE_COMMENT: "Mesajul tău",
            CONTACT_PERSON: "Persoană contact",
            BENEFICIARY: "Beneficiar",
            IS_PAID: "Plată",
            IS_SIGNED: "Semnătură",
            CADASTRAL_NUMBER: "Nr. cadastral",
            COMMENTS: "Comentarii",
            TOTAL_TAX: "Total",
            ALLOCATED_DOCUMENT_SERIES: "Cod ieșire",
            PAYMENT_TYPE: "Tip de plată",
            PAYMENT_TYPE_CARD: "Card online",
            PAYMENT_TYPE_OP: "Ordin de plată",
            PAYMENT_TYPE_DISCOUNT: "Scutire plată",
            TRANSACTION_NUMBER: "Număr tranzacție",
            QUICK_SEARCH_VALUES: "Căutare rapidă",
            REJECTED_REASON: "Motivul respingerii - vizibil doar la funcționari",
            PAYMENT: {
                CREATED_DATE: "Dată înregistrare",
                AMOUNT: "Sumă",
                CURRENCY: "Monedă",
                DESCRIPTION: "Detalii",
                PAYMENT_TYPE: "Tip plată",
                STATUS: "Status",
                EMAIL: "Email",
                CLIENT_USER_NAME: "Nume client",
                CLIENT_USER_EMAIL: "Email client",
                CLIENT_USER_PERSONAL_NUMERIC_CODE: "CNP",
                PRODUCT_IDENTIFIER_TYPE_DESCRIPTION: "Descriere",
                BT_PAYMENT_ID: "ID tranzacție",
                APPLICATION_TYPE_NAME: "Tip cerere"
            }
        },
        ENUMS: {
            STATUS: {
                NEW: "Nouă",
                PENDING: "În verificare",
                RESOLVED: "Soluționată",
                REJECTED: "Respinsă",
                APPROVED: "În lucru",
                DRAFT: "Draft",
                GENERATED: "Generat",
                SIGNED: "Semnat",
                SIGN_PENDING: "În semnare",
                CLOSED: "Finalizată",
                REVIEW: "În clarificări",
                REJECTED_IN_PROGRESS: "În respingere",
                ERROR: "Eroare",
                SEALED: "Sigilat",
                INTERNAL: "Intern",
                SEAL_ERROR: "Eroare sigilare",
                SEAL_PENDING: "În sigilare",
                SIGN_APPLICANT_USER_PENDING: "Semnare solicitant",
            },
            REAL_ESTATE_TYPE: {
                LAND: "Teren",
                LAND_CONSTRUCTIONS: "Teren + Constructii",
            },
            PAYMENT_TYPE: {
                DISCOUNT: "Scutire plată",
                LINK: "Link",
                CARD: "Card",
                OP: "Ordin plată",
            },
            PAYMENT_STATUS: {
                CREATED: "Înregistrată",
                SUCCESSFUL: "Procesată",
                FAILED: "Eșuată",
            },
        },
        PLACEHOLDERS: {
            BENEFICIARY: "Beneficiar",
            SERVICE: "Alege",
            ALLOCATED_STAFF: "Alege",
            IDENTIFICATION_NUMBER: "Cod intrare",
            APPLICATION_TYPE_NAME: "Tip cerere",
            CADASTRAL_NUMBER: "Nr CF",
            APPLICATION_TYPE_PURPOSE: "Scopul cererii",
            TERRITORIAL_ADMINISTRATIVE_UNIT: "UAT",
            STATUS: "Status",
            COUNTY: "Județ",
            LOCALITY: "Localitate",
            RESPONSE_DUE_IN: "Timp rămas",
            VILLAGE: "Localitate",
            TERRITORIAL_ADMINISTRATIVE_UNIT_TYPE: "Tip UAT",
            CREATED_BY: "Creat de",
            EMPLACEMENT_LOCALITY: "Localitate",
            COMMENTS: "Comentarii",
            MESSAGE: "Mesaj",
            ASSIGNED_TO: "Responsabil",
            EMAIL: "E-mail",
            QUICK_SEARCH_VALUES: "Căutare rapidă",
            PAYMENT: {
                CREATED_DATE: "Dată înregistrare",
                AMOUNT: "Sumă",
                CURRENCY: "Monedă",
                DESCRIPTION: "Detalii",
                PAYMENT_TYPE: "Tip plată",
                STATUS: "Status",
                EMAIL: "Email",
                CLIENT_USER_NAME: "Nume client",
                CLIENT_USER_EMAIL: "Email client",
                CLIENT_USER_PERSONAL_NUMERIC_CODE: "CNP",
                PRODUCT_IDENTIFIER_TYPE_DESCRIPTION: "Descriere",
                BT_PAYMENT_ID: "ID tranzacție",
                APPLICATION_TYPE_NAME: "Tip cerere"
            }
        },
        FORM: {
            TITLE: "Formular cerere nouă",
            SUBMIT_BUTTON: "Trimite cererea",
            SAVE_AS_DRAFT_BUTTON: "Salvează ca draft",
            CANCEL_BUTTON: "Anulează cererea",
            DETAILS_SECTION: "Detalii cerere",
            TAXES_BUTTON: "Calculează taxe",
            TAXES_ERROR:
                "Vă rugăm selectați tipul cererii și completați suprafața terenului",
            WIZARD_TITLE: "Depunere cerere",
            GO_BACK: "Înapoi",
            GENERATE_DOCUMENT: "Finalizează document",
            NO_TEMPLATE: "Nu există un template pentru acest tip de cerere",
            PAY_BUTTON: "Plătește",
            CONTINUE_BUTTON: "Continuă",
            SIGN_BUTTON: "Semnează și depune",
            APPLY_BUTTON: "Depune cerere",
            SEND_MESSAGE_BUTTON: "Trimite mesajul",
            PREVIEW_DOC: "Previzualizare",
            SEND_REVIEW: "Trimite clarificări",
            PENDING_RELATED_APPLICATIONS: "Avizul de primar în lucru",
            PENDING_INTERNAL_APPLICATIONS: 'Documente interne în lucru',
            HELP_DESCRIPTION: "Dacă ai nevoie de ajutor accesează pagina",
            HELP_FREQUENT_QUESTIONS: "Întrebări frecvente",
            DOWNLOAD_BUTTON: "Descarcă",
            VALIDATE_FILE_BUTTON: "Validează și salvează fișier",
            GIS_ATTACHMENTS: "Anexe GIS",
        },
        NOTIFIERS: {
            CREATED_SUCCESS_TITLE: "Cerere trimisă",
            CREATED_SUCCESS_TEXT:
                "Cererea cu numărul {number} a fost trimisă cu succes. După o analiză și verificare vei fi notificat în legătură cu rezolvarea acesteia.",
            DRAFT_SAVED_SUCCESS: "Cererea a fost salvată ca draft",
            CHOOSE_SIGN_PACKAGE: "Vă rugăm alegeți pachetul de semnături",
            OFFLINE_SIGN_FAILED: "Documentele nu sunt semnate",
            PAYMENT_FAILED: "Plată eșuată",
            PAYMENT_SUCCESS: "Plata a fost finalizată cu succes",
            OP_CANCELLED_SUCCESS: "Plata a fost anulată cu succes.",
            OP_CANCELLED_SUCCESS_TEXT: "Veți putea reveni la pagina cererii.",
            INVALID_PROFILE_FOR_SIGNING_TITLE: "Datele din profil nu sunt valide",
            INVALID_PROFILE_FOR_SIGNING_TEXT:
                "Te rugăm să verifici CNP-ul și datele cărții de identitate din secțiunea <b>Profilul meu</b> necesare pentru semnarea online!",
            INVALID_PROFILE_FOR_SIGNING_BUTTON_TEXT: "Mergi la Profilul meu",
            INVALID_STAFF_ENROLLMENT_TITLE: "A apărut o eroare la procesul de semnare",
            INVALID_STAFF_ENROLLMENT_TEXT: "Te rugăm să te înrolezi pentru a putea semna",
            INVALID_STAFF_ENROLLMENT_BUTTON_TEXT: "Mergi la Profilul meu",
            DUPLICATE_BENEFICIARY_PNC: "Beneficiarul cu același CNP ({pnc}) este deja adăugat.",
            GENERATE_OFFLINE_PACKAGE: "Cererea se descarcă. Vă rugăm așteptați!",
            REVIEW_SENT: "Clarificările au fost trimise",
            REVIEW_NOT_SENT: "A apărut o eroare la trimiterea clarificărilor. Te rugăm sa încerci din nou",
            UPLOADING_FILES: "Fișierele sunt în curs de încărcare",
            MISSING_UAT: "Localitatea selectată pentru amplasament(e) nu e disponibilă",
            UNAUTHORIZED_PAYMENT: "Ne cerem scuze, momentan nu se poate plăti această cerere. Tipologia de cerere este momentan inactivă.",
            GENERATION_ERROR: "A apărut o eroare la generarea documentului!",
            GENERATION_ERROR_TEXT: "Vă rugăm să reîncercați trimiterea clarificărilor.",
            SENDING_ERROR: "A apărut o eroare. Vă rugăm să reîncercați depunerea cererii mai târziu",
            BULK_SIGN_LIMIT: "Vă rugăm să nu selectați mai mult de {number} cereri pentru semnare bulk",
            REVIEW_APPLICATION_TYPE: "Vă rugăm să setați din secțiunea admin nota de clarificări aferentă",
            INVALID_GIS_FILE: "Fișierul încărcat nu este valid. Vă rugăm să încărcați alt fișier!",
            DOC_RECEIVED_BULK_SIGN_LIMIT: "Maxim {number} documente pot fi semnate o dată.",
        },
        VIEW: {
            TITLE_STAFF: "Registru cereri",
            TITLE: "Cereri",
            SAVE_AS_DRAFT_BUTTON: "Salvează ca draft",
            REASSIGN_STAFF: "Realocare cerere",
            ASSIGN_STAFF: "Alocare cerere",
            SEND_RESPONSE: "Trimite răspuns",
            APPLICATION_DETAILS_SECTION_TITLE: "Detalii cerere",
            BENEFICIARY_DETAILS_SECTION_TITLE: "Detalii beneficiari",
            BENEFICIARY_DETAILS_TITLE: "Beneficiar",
            REAL_ESTATE_DETAILS_SECTION_TITLE: "Detalii imobil",
            REAL_ESTATE_EMPLACEMENT_DETAILS_SECTION_TITLE: "Detalii amplasament",
            APPLICANT_DETAILS_SECTION_TITLE: "Detalii solicitant / proiectant",
            PAYMENT_DETAILS_SECTION_TITLE: "Plăti",
            ATLAS_DETAILS_SECTION_TITLE: "Previzualizare din atlas teritorial",
            ATTACHMENTS_DETAILS_SECTION_TITLE: "Documente",
            NEXT_STEP: "Pasul următor",
            SIGN_DOCUMENT: "Semnează document",
            ASSIGN_STAFF_MESSAGE: "Ești sigur că vrei să preiei această cerere?",
            APPROVE_DOCUMENT: "Aprobă",
            REJECT_DOCUMENT: "Respinge",
            APPROVE_DOCUMENT_MESSAGE: "Ești sigur că vrei să aprobi această cerere?",
            REJECT_DOCUMENT_MESSAGE: "Ești sigur că vrei să respingi această cerere?",
            GENERATE_DOCUMENT_MESSAGE: "Documentul a fost generat",
            GENERATE_DOCUMENT_ERROR: "Documentul nu poate fi generat",
            SIGN_DOCUMENT_ERROR: "Documentul nu poate fi semnat",
            SIGN_SUCCESS: "Documentul a fost semnat",
            AUTOSAVE_SUCCESS: "Salvat cu succes",
            AUTOSAVE_FAILED: "Erroare la salvare",
            SEND_CLARIFICATIONS: "Necesită clarificări",
            CREATE_INTERNAL_DOCUMENT: "Creează document intern",
            RETURN_DOCUMENT: "Regenerare document",
            RETURN_DOCUMENT_MESSAGE: "Sunteți sigur că vreți să returnați acest document? Această acțiune este ireversibilă și presupune regenerarea actului.",
            SIGN_ATTACHMENTS_BUTTON: "Semnează anexe",
            DOWNLOAD_COMPRESSION: "Descarcă .zip",
            VIEW_INTERNAL_DOCUMENTS: "Vizualizează documente interne",
            CLOSE_APPLICATION: "Finalizează cererea",
            CREATE_PAYMENT_LINK: "Trimite link plată",
            UPLOAD_FILES: "Vă rugăm să încărcați documentele necesare în secțiunea de Anexe de mai jos",
            PROCESS_ERROR: "Eroare proces",
            START_PROCESS: "Pornește proces",
            SEAL_ATTACHMENTS_BUTTON: "Sigilează anexe",
        },
        CONFIRM_SIGN_MODAL: {
            TITLE: "Validare semnătură digitală",
            MESSAGE_SENT:
                "S-a trimis un SMS la numărul de telefon <strong>{0}</strong>. Adaugă codul primit, pentru a semna cererea folosind semnătura electronică și a trimite cererea.",
        },
        TABS: {
            APPLICATION: "Detalii cerere",
            DOCUMENTS: "Documente",
            ACTIVITY: "Activități",
            PAYMENTS: "Plăți",
            DOCUMENT_CREATED: "Cereri",
            DOCUMENT_RECEIVED: "Acte emise",
            DOCUMENT_REVIEW: "Clarificări",
            DOCUMENT_INTERNAL: "Interne",
            REAL_ESTATE: "Imobil",
            TAXES: "Taxe",
            BENEFICIARY: "Beneficiar",
            NEW_APPLICATION: "Detalii",
            SIGN: "Semnătură",
            SUMMARY: "Rezumat",
            CERTIFICAT_URBANISM: "Certificat urbanism",
        },
        TERRITORIAL_ATLAS: {
            HEADER: "Previzualizare din atlas teritorial",
            SUBTITLE: "Harta se populează pe măsură ce se adaugă amplasamente.",
            GENERATE_BUTTON: "Vizualizează",
            REAL_ESTATES_LABEL: "Vizualizează amplasamentul",
            PLACEHOLDER:
                "Introdu datele imobilului apoi apasa butonul “Genereaza” pentru previzualizarea hartii.",
            NOT_FOUND:
                "Ne pare rău numărul cadastral nu a fost găsit. Incercați din nou!",
            ERROR: "Ne pare rău sincronizarea nu a fost cu succes. Incercați din nou!",
        },
        PAYMENT: {
            SECTION_TITLE: "Plătește",
            TITLE: "Alege modalitatea de plată",
            HELP_TEXT_CARD:
                "Pentru depunerea cererii online taxele se pot plăti online cu card bancar. Procesul de plată se poate face de la distanță, iar pentru aceasta aveți nevoie de confirmarea plății conform setărilor avute de banca emitentă a cardului.",
            HELP_TEXT_OP:
                "Pentru depunerea cererii online taxele se pot plăti prin ordin de plată. Online prin transfer bancar sau fizic de la ghișeul băncilor direct în contul Consiliului Județean Cluj (cod IBAN RO04TREZ21621160203XXXXX, CIF 4288110). În formularul de plată se completează tipul și obiectivul cererii.",
            STATUS_PAID: "achitat",
            STATUS_UNPAID: "neachitat",
            SELECT: {
                ONLINE: "Plată online cu card bancar",
                OFFLINE: "Plată prin ordin de plată",
            },
        },
        PAYMENT_ORDER: {
            TITLE: "Ordin de plată",
            SECTION_TITLE: "Adaugă ordin de plată",
            FIELDS: {
                ORDER_NUMBER: "Număr ordin",
                PAYMENT_DATE: "Data plății",
                FILE: "Încarcă documentul cu ordinul de plată",
            },
            CANCEL: "Renunță",
        },
        DISCOUNTS: {
            TITLE: 'Alege o scutire de plată',
            NO_DISCOUNT: 'Nici o scutire',
            HELP_TEXT:
                "Pentru depunerea cererii online se poate aplica o scutire de la plata taxelor conform codului fiscal și legilor aflate în vigoare.",
            DESCRIPTION_HEADER: "Scutirea se aplică doar pentru:",
            DESCRIPTION_WARNING: "Pentru aplicarea cu succes a scutirii cererea trebuie să fie însoțită de acte doveditoare atașate ca și anexe la această cerere."
        },
        MODALS: {
            ADD: "Adaugă",
            CANCEL: "Renunță",
            SAVE: "Salvează",
            SIGN_STATUS: {
                TITLE: "Status semnare",
                IDENTIFICATION_NUMBER: "Cod de intrare",
                LOCALITY: "Municipiu/Oraș/Comună",
                SIGNING_BY: "În curs de semnare la:",
                LAST_SIGNING_DATE: "Data ultimei semnări",
                CITIZEN_SIGNING: "Cetățean",
                LAST_SIGNING_DATE_CITIZEN: "Data semnare cetățean"
            }
        },
        SIGNATURE: {
            TITLE: "Alege modalitatea de semnare",
            SECTION_TITLE: "Semnează cererile și anexele",
            TABS: {
                ONLINE: "Semnare online",
                OFFLINE: "Semnare offline",
            },
            ONLINE: {
                HELP_TEXT:
                    "Pentru depunerea cererii online aceasta va fi semnată digital prin semnătură digitală. Procesul de semnare se poate face de la distanță, iar pentru aceasta aveți nevoie de o semnătură digitală.",
                INFO_TEXT:
                    "Pentru a intermedia achiziționarea certificatului digital pentru semnături de la certSIGN, vom folosi datele de identificare din contul tău. Le vei furniza o singură dată, iar pe baza lor va fi creat un cod unic de identificare care va garanta autenticitatea solicitărilor tale.",
                PACKAGE_LABEL: "Costul acestora va fi inclus în total.",
                SUMMARY_DIGITAL_SIGNATURE_LABEL: "Semnături digitale",
                SUMMARY_REQUIRED_SIGNATURES: "Necesare",
                SUMMARY_AVAILABLE_SIGNATURES: "Disponibile",
                SUMMARY_LEFTOVER_SIGNATURES: "Vor rămane",
                SUMMARY_TEXT: "Semnătură electronică la distanță",
            },
            OFFLINE: {
                HELP_TEXT:
                    'Alege "SEMNARE ONLINE" dacă dorești să achiziționezi un certificat pentru semnăturile digitale necesare, sau "SEMNARE OFFLINE" dacă deții deja unul de la un furnizor autorizat.',
                INFO_TEXT:
                    "Descarcă documentul pe dispozitivul tău, semnează-l și încarcă documentul pentru a continua. Nota bene: este nevoie de semnătură digitală validată cu certificat calificat, nu electronică (sau olografă).",
                ACTION_DOWNLOAD_TITLE: "Descarcă cererea",
                ACTION_DOWNLOAD_SUBTITLE: "care trebuie semnată digital",
                ACTION_UPLOAD_TITLE: "Încarcă cererea",
                ACTION_UPLOAD_SUBTITLE: "care trebuie semnată digital",
                STEP_DOWNLOAD: "Descarcă",
                STEP_SIGN: "Semnează",
                STEP_VERIFY: "Verificare",
                STEP_CHECKED: "Documente ok",
                SUMMARY_TEXT: "Semnătură electronică personală",
                UPLOAD_NOTIFICATION:
                    "O dată cu încărcarea documentelor semnate, cererea nu va mai putea fi modificată. Ești sigur ca vrei să continui?",
            },
        },
        CLARIFICATION_MODAL: {
            TITLE: "Trimitere răspuns",
        },
        SEND_REVIEW_MODAL: {
            TITLE: "Trimitere clarificări",
        },
        SUMMARY: {
            SIGN_TYPE_TITLE: "Modalitate de semnare",
            APPLICATION_DETAIL_TITLE: "Detalii cerere",
            PAYMENT_TYPE_TITLE: "Modalitate de plată",
            SIGN_TYPE_FOOTER: "Asigurat prin CertSign",
            APPLICATION_OBJECTIVE: "Obiectivul cererii",
            APPLICANT: "Solicitant",
            PAYMENT_TYPE: "Card online",
            PAYMENT_TYPE_OP: "Ordin de plată",
            FILE_NAME: "Ordin de plată",
            PAYMENT_TYPE_HELP_TEXT:
                "După inițierea plății, vei fi redirecționat către procesatorul de plăți pentru a efectua plata.",
            PAYMENT_TYPE_HELP_TEXT_FAILED: "Plata nu a putut fi efectuată.",
            PAYMENT_TYPE_HELP_TEXT_SUCCESS: "Plata a fost efectuată cu succes.",
            PAYMENT_TYPE_OP_HELP_TEXT:
                "După inițierea plății, vei fi redirecționat către pagina de încărcare a ordinului de plată",
            PAYMENT_TYPE_HELP_OP_TEXT_SUCCESS:
                "Ordinul de plată a fost încărcat cu succes.",
            PAYMENT_DISCOUNT: "Scutire plată",
            PAYMENT_DISCOUNT_TEXT: "Sunteți scutit de la plată. După confirmare, veți fi redirecționat către pagina cererii.",
            PAYMENT_DISCOUNT_TEXT_SUCCESS: "Scutirea de plată a fost înregistrată.",
            DETAILS: "Desfășurător",
            CERTIFICATE_TOTAL_TAXES: "Total costuri certificate digitale",
            TOTAL_TAXES: "Total taxe și avize",
            SECTION_TITLE: "Revizuiește și trimite cererea",
            TOTAL: "Total",
            SELECTED_REAL_ESTATES_TEXT:
                "nici un imobil ales | + 1 imobil ales | + {count} imobile alese",
            PAY_AS_YOU_GO_SUMMARY_TEXT: "Semnatură digitală",
            TAX_TEXT: "Taxă",
            DISCOUNT: "Scutire"
        },
        ENROLLMENT_DETAILS: {
            PERSON: {
                HEADER: "Tip persoană",
                INDIVIDUAL: "Persoană fizică",
                LEGAL: "Persoană juridică",
            },
            CITIZENSHIP: {
                HEADER: "Cetățenie",
                ROMANIAN: "Cetățean romȃn",
                FOREIGNER: "Cetățean străin",
            },
            PERSONAL_NUMERIC_CODE: "CNP",
            ADDRESS: "Adresă domiciliu",
            CONTACT: "Contact",
            ADDRESS_COUNTY: "Județul",
            ADDRESS_STREET: "Str.",
            ADDRESS_NUMBER: "nr.",
            ADDRESS_STAIRCASE: "scara",
            ADDRESS_APARTMENT: "ap.",
        },
        SEND_SUCCESS_TITLE:
            "Felicitări, solicitarea ta a fost trimisă fără a genera risipă de hârtie! Până acum am salvat <span>{treeNumbers}</span> de copaci.",
        SEND_SUCCESS_TEXT:
            "Cererea cu numarul <strong>{number}</strong> a fost înregistrată cu succes.</br> După ce o analizăm și verificăm, vei fi notificat (aici și prin email) în legatură cu rezolvarea acesteia.",
        PAYMENT_SUCCESS_TITLE: "Plata a fost înregistrată cu succes.",
        PAYMENT_REDIRECT_TEXT:
            "Veți fi redirecționat în cateva secunde la pagina cererii. Dacă nu sunteți redirecționat automat va rugăm sa dați click pe butonul de mai jos.",
        PAYMENT_FAILED_TITLE:
            "A apărut o eroare la procesarea plății, te rugăm încearcă din nou.",
        PAYMENT_NOTIFICATION_TEXT:
            "Cererea nu a fost înca depusă, va rugăm să urmați pasul următor pentru depunere.",
        MISSING_PAYMENT_CALLBACK_PAYLOAD:
            "A apărut o eroare la procesarea plății, te rugăm încearcă din nou.",
        RESPONSE_MODAL: {
            APPROVE_APPLICATION_TITLE: "Notificare aprobare",
            REJECT_APPLICATION_TITLE: "Motiv respingere",
        },
        ASSIGN_MODAL_TITLE: "Asignare Cerere",
        ASSIGN_SUCCESS:
            "Cererea a fost asignată la <strong>{name}</strong> cu succes!",
        SIGN_CONFIRMATION: {
            INVALID_PIN: "Codul nu este valid, vă rugăm reîncercați",
            PIN_EXPIRED: "Codul a expirat, vă rugăm reîncercați",
            SESSION_EXPIRED: "Sesiunea a expirat, vă rugăm reîncercați",
            ERROR: "A apărut o eroare, vă rugăm reîncercați",
            BULK_FAILED: "Următoarele cereri nu pot fi semnate {0}",
        },
        SIGN_AND_APPLY_LOADER: {
            TITLE: "Depunerea durează câteva momente",
            TEXT: "Te rugăm să aștepți, depunerea cererii este în progres și poate dura câteva minute, fără a genera risipă de hârtie și timp.",
        },
        UPLOAD_DOCUMENTS_MODAL: {
            TITLE: "Adaugă documente",
        },
        CREATE_PAYMENT_MODAL: {
            TITLE: "Creează plată",
            BUTTON: "Trimite",
        },
        DELETE_CONFIRMATION: "Ești sigur că dorești să ștergi acest document?",
        INFO_TEXT: {
            FIRST_CITIZEN_REVIEW_DATE: "Prima clarificare primită",
            LAST_CITIZEN_REVIEW_DATE: "Ultima clarificare primită",
            FIRST_STAFF_REVIEW_DATE: "Prima clarificare trimisă",
            LAST_STAFF_REVIEW_DATE: "Ultima clarificare trimisă",
        },
        COPY_FILE_MODAL: {
            TITLE: 'Setează nume fișier'
        },
        BULK_COPY_FILE_MODAL: {
            TITLE: 'Selectează fișierele copiate',
            CONFIRM_MSG: "Sunteti sigur ca vreti sa copiati toate fisierele?"
        }
    },
    ARCHIVE: {
        BUTTONS: {
            MANUAL_ARCHIVE: "Arhivare manuală",
            ADD_WORKSPACE: "Adaugă workspace",
            DOWNLOAD: "Descarcă",
        },
        MENU: {
            DASHBOARD: "Dashboard",
            DOCUMENTS: "Documente",
        },
        WORKSPACE: {
            LIST: {
                TITLE: "Workspaces",
            },
            FIELDS: {
                NAME: "Denumire",
                ENTITY_TYPE: "Tip",
                CREATED_DATE: "Arhivare",
                DIMENSION: "Dimensiune",
                FOLDERS_NUMBER: "Nr foldere",
                DESCRIPTION: "Descriere",
            },
            PLACEHOLDERS: {
                NAME: "Denumire",
                ENTITY_TYPE: "Tip",
                CREATED_DATE: "Arhivare",
            },
            FORM: {
                EDIT_TITLE: "Editează workspace",
                ADD_TITLE: "Adaugă workspace",
            },
        },
        SUBFOLDER: {
            LIST: {
                TITLE: "Documente",
            },
            FIELDS: {
                NAME: "Denumire",
                TYPE: "Tip",
                CREATED_DATE: "Arhivare",
                DIMENSION: "Dimensiune",
                DOCUMENT_NUMBER: "Nr Documente",
                WORKSPACE: "Workspace",
                DESCRIPTION: "Descriere",
                DOCUMENT_TYPE_CODE: "Tip document",
            },
            PLACEHOLDERS: {
                NAME: "Denumire",
                ACHIVE_DATE: "Arhivare",
            },
            ACTIONS: {
                EDIT: "Modifică Folder",
                ADD: "Adaugă Folder",
            },
            FORM: {
                WORKSPACE: "Workspace",
                TYPE: "Tip Document corelat",
                NAME: "Denumire Folder",
            },
        },
        DOCUMENTS: {
            LIST: {
                TITLE: "Documente",
            },
            FIELDS: {
                DOCUMENT_NAME: "Denumire",
                DOCUMENT_TYPE_NAME: "Tip",
                DOCUMENT_SIZE: "Dimensiune",
                CREATED_DATE: "Data creării",
                UPDATED_DATE: "Data modificării",
            },
            PLACEHOLDERS: {
                NAME: "Denumire",
                ACHIVE_DATE: "Arhivare",
            },
            ACTIONS: {
                EDIT: "Modifică Folder",
                ADD: "Adaugă Folder",
            },
        },
        DOCUMENT: {
            TITLE_MANUAL_ARCHIVE: "Arhivare manuală",
            TITLE_VIEW_ARCHIVE: "Vizualizare arhivă",
            HEADERS: {
                ATTACHMENTS: "Atașamente",
                DETAILS: "Detalii",
                METADATA: "Metadate",
            },
            FIELDS: {
                WORKSPACE: "Workspace",
                DOCUMENT_NAME: "Nume Document",
                DOCUMENT_FOLDER: "Folder",
                DOCUMENT_TYPE: "Tip document",
            },
        },
        CONFIRMATION_TEXT:
            "Pentru consistența datelor va rugam sa verificați ca acestea sunt valide si conforme cu documentele arhivate. Pentru orice modificari ulterioare va rugam sa contactati un administrator al sistemului. Sunteti sigur ca doriti sa salvati documentul?",
        MESSAGES: {
            SUCCESS: "Arhivare manuală realizată cu succes!",
            ERRORS: "Eroare la arhivarea manuală!",
        },
        ATTACHMENTS: {
            PLACEHOLDER: "fișiere selectate",
            EMPTY: "Nu există documente atașate.",
            EMPTY_WARNING:
                "Pentru a salva, arhiva trebuie sa conțină fișiere atașate.",
            LABEL: "Fișier",
            ADD_BUTTON: "Adaugă atașament",
        },
        REPORTS: {
            WORKSPACE_LABEL: "Workspace",
            WORKSPACE_DIMENSION: {
                TITLE: "Total spațiu arhivat",
                DESCRIPTION: "Raport workspace",
            },
            EXPIRATION: {
                TITLE: "Termen expirare",
                DESCRIPTION: "Documentele ce urmează să expire",
                MONTHLY: "Luna aceasta",
                WEEKLY: "Săptămâna aceasta",
            },
            WORKSPACE_DOCUMENTS: {
                TITLE: "Total număr documente arhivate",
                DESCRIPTION: "Raport workspace",
            },
            WORKSPACE_FOLDERS_DIMENSION: {
                TITLE: "Total dimensiune documente arhivate per workspace",
                CHART_LABELS_TITLE: "Listă foldere",
                DESCRIPTION: "Raport foldere din workspace",
            },
            WORKSPACE_FOLDERS_DOCUMENTS: {
                TITLE: "Total număr documente arhivate per workspace",
                CHART_LABELS_TITLE: "Listă foldere",
                DESCRIPTION: "Raport foldere din workspace",
            },
            WORKSPACE_PROGRESS_ANNUAL: {
                TITLE_DOC: "Progres de arhivare {period} - documente",
                TITLE_DIMENSION: "Progres de arhivare {period} - dimensiune",
                TITLE_DESCRIPTION_DOC: "Fișiere arhivate",
                TITLE_DESCRIPTION_DIMENSION: "Dimensiune fișiere arhivate",
                TOTAL_DESCRIPTION_DOC: "Documente în perioada selectată",
                TOTAL_DESCRIPTION_DIMENSION: "Dimensiune în perioada selectată",
                CHART_TITLE: "Analiză",
                Y_AXIS_DOC: "Documente",
                Y_AXIS_DIMENSION: "Dimensiune",
            },
        },
        PERIOD: {
            ANNUALY: "anual",
            MONTHLY: "lunar",
            WEEKLY: "săptămânal",
        },
    },
    FORMS: {
        LIST: {
            TITLE: "Interfețe formulare",
            ADD_BUTTON: "Adaugă formular",
            TABLE_HEADER: "Modele interfețe formulare",
        },
        FIELDS: {
            NAME: "Denumire",
            STATUS: "Stare",
            DESCRIPTION: "Descriere",
        },
        PLACEHOLDERS: {
            NAME: "Nume",
        },
        ENUMS: {
            STATUS: {
                DRAFT: "Draft",
                PUBLISHED: "Public",
            },
        },
        CREATE_MODAL: {
            TITLE: "Salvare interfață formular",
        },
        COMPONENTS: {
            FIELDS: {
                input: "Input",
                textarea: "Textarea",
                number: "Number",
                radio: "Radio",
                checkbox: "Checkbox",
                time: "Time",
                date: "Date",
                rate: "Rate",
                color: "Color",
                select: "Select",
                switch: "Switch",
                slider: "Slider",
                text: "Text",
                blank: "Custom",
                fileupload: "File",
                imgupload: "Image",
                editor: "Editor",
                cascader: "Cascader",
                table: "Sub-table",
                grid: "Grid",
                section: "Section",
                inline: "Inline",
                tabs: "Tabs",
                divider: "Divider",
                file: "File",
                html: "HTML",
                "beneficiary-list": "Beneficiar",
                "applicant-form": "Solicitant",
                "application-detail-form": "Detalii cerere",
                "real-estate-list": "Imobil",
                "decision-list": "Hotărâri",
                attachments: "Atașamente",
                "urbanism-regulation-picker": "Reglementări",
                "related-application": "Documente de legătură",
                "offender-list": "Contravenient",
                "witness-list": "Martor",
                "realty-list": "Detalii construcție",
                "staff-form": "Detalii funcționar",
                "gis-attachments": "Anexe GIS",
                "ac-tax-validator": "Valoare minima investitie",
                "richtexteditor": "Html editor",
                "locality-list": "Detalii amplasamente",
                "buildings-list": "Corpuri",
                "urbanism-regulations-list": "Detalii RLU",
            },
            BASIC: {
                TITLE: "Basic Component",
            },
            ADVANCE: {
                TITLE: "Advance Component",
            },
            LAYOUT: {
                TITLE: "Layout",
            },
            CUSTOM: {
                TITLE: "Custom Component",
            },
        },
        DESCRIPTION: {
            CONTAINER_EMPTY:
                "You can drag and drop the item from the left to add components",
            CONFIG_EMPTY: "Please add a component",
            TABLE_EMPTY:
                "You can drag and drop the item from the left to add components",
            UPLOAD_JSON_INFO:
                "There is the format of JSON below，you can overwrite it with you own JSON code",
        },
        MESSAGE: {
            COPY_SUCCESS: "Copy Successed",
            VALID_ERROR: "Vă rugăm să completați toate câmpurile obligatorii.",
        },
        ACTIONS: {
            IMPORT: "Import JSON",
            CLEAR: "Clear",
            PREVIEW: "Preview",
            JSON: "Generate JSON",
            CODE: "Generate Code",
            GET_DATA: "Get Data",
            RESET: "Reset",
            COPY_DATA: "Copy Data",
            CANCEL: "Cancel",
            CONFIRM: "Confirm",
            ADD_OPTION: "Add Option",
            ADD_COLUMN: "Add Column",
            ADD_TAB: "Add Tab",
            UPLOAD: "Upload",
            ADD: "Add",
        },
        CONFIG: {
            FORM: {
                TITLE: "Form Attribute",
            },
            WIDGET: {
                TITLE: "Component Attribute",
                MODEL: "ID",
                NAME: "Name",
                META: "Meta",
                WIDTH: "Width",
                HEIGHT: "Height",
                SIZE: "Size",
                LABEL_WIDTH: "Label Width",
                CUSTOM: "Custom",
                PLACEHOLDER: "Placeholder",
                LAYOUT: "Layout",
                BLOCK: "Block",
                INLINE: "Inline",
                CONTENT_POSITION: "Content Position",
                LEFT: "Left",
                RIGHT: "Right",
                CENTER: "Center",
                SHOW_INPUT: "Display Input Box",
                MIN: "Minimum",
                MAX: "Maximum",
                STEP: "Step",
                MULTIPLE: "Multiple",
                FILTERABLE: "Searchable",
                ALLOW_HALF: "Allow Half",
                SHOW_ALPHA: "Support transparency options",
                SHOW_LABEL: "Show lable",
                OPTION: "Option",
                STATIC_DATA: "Static Data",
                REMOTE_DATA: "Remote Date",
                REMOTE_FUNC: "Remote Function",
                VALUE: "Value",
                LABEL: "Label",
                CHILDREN_OPTION: "Sub-Option",
                DEFAULT_VALUE: "Default Value",
                LIMIT: "Maximum Upload Count",
                IMAGE_ACTION: "Picture upload address",
                TIP: "Text Prompt",
                ACTION: "Upload Address",
                DEFAULT_TYPE: "Data Type",
                STRING: "String",
                OBJECT: "Object",
                ARRAY: "Array",
                NUMBER: "Number",
                BOOLEAN: "Boolean",
                INTEGER: "Integer",
                FLOAT: "Float",
                URL: "URL",
                EMAIL: "E-mail",
                HEX: "Hexadecimal",
                GUTTER: "Grid Spacing",
                COLUMN_OPTION: "Column Configuration",
                SPAN: "Grid spans",
                JUSTIFY: "Horizontal Arrangement",
                JUSTIFY_START: "Start",
                JUSTIFY_END: "End",
                JUSTIFY_CENTER: "Center",
                JUSTIFY_SPACE_AROUND: "Space Around",
                JUSTIFY_SPACE_BETWEEN: "Space Between",
                ALIGN: "Vertical Arrangement",
                ALIGN_TOP: "Top",
                ALIGN_MIDDLE: "Middle",
                ALIGN_BOTTOM: "Bottom",
                TYPE: "Type",
                DEFAULT: "Default",
                CARD: "Tabs",
                BORDER_CARD: "Border-Card",
                TAB_POSITION: "Tab Position",
                TOP: "Top",
                BOTTOM: "Bottom",
                TAB_OPTION: "Label Configuration",
                TAB_NAME: "Tab Name",
                CUSTOM_CLASS: "Custom Class",
                ATTRIBUTE: "Attribute Action",
                DATA_BIND: "Data Binding",
                HIDDEN: "Hidden",
                READONLY: "Read Only",
                DISABLE: "Disable",
                CALCULATOR: "Calculator",
                CONVERT_NUMBER_TO_TEXT: "Convert number to text",
                CONVERT_NUMBER_TO_TEXT_FROM_FIELD: "Convert number to text from Field:",
                SUM_NUMBERS: "Sum numbers",
                SUM_NUMBERS_FROM_FIELDS: "Sum numbers from Fields:",
                MULTIPLY_NUMBERS: "Multiply numbers",
                MULTIPLY_NUMBERS_FROM_FIELDS: "Multiply numbers from Fields:",
                SUM_MONTHS: "Sum months",
                SUM_MONTHS_FROM_FIELDS: "Sum months from Fields:",
                DIFF_MONTHS: "Diff months",
                DIFF_MONTHS_FROM_FIELDS: "Diff months from Fields:",
                EDITABLE: "Text box is editable",
                CLEARABLE: "Display Clear Button",
                ALLOW_CONTROL: "Use the arrow for time selection",
                IS_DELETE: "Deletable",
                IS_EDIT: "Editable",
                SHOW_PASSWORD: "Display Password",
                VALIDATE: "Validation",
                REQUIRED: "Required",
                PATTERN_PLACEHOLDER: "Fill in the regular expressions",
                PATTERN_MESSAGE_PLACEHOLDER: "Regular exppressions validation message",
                NEW_OPTION: "New Option",
                TAB: "Tab",
                VALIDATOR_REQUIRED: "Required",
                VALIDATOR_TYPE: "Invaild format",
                VALIDATOR_PATTERN: "Unmatched pattern",
                COLLAPSIBLE: "Collapsible",
                COLLAPSED: "Collapsed (by default)",
                SECTION_TITLE: "Title",
                IS_CONDITIONED: "Is conditioned",
                CONDITION: "Condition",
                FONT_SIZE: "Font size",
                IS_BOLD: "Bold",
                HIDE_LABEL: "Hide label",
                SHOW_TOOLTIP: "Show tooltip",
                TOOLTIP_TEXT: "Text tooltip",
                ROWS: "Rows",
                MAX_ROWS: "Max rows",
                PLAINTEXT: "Read only",
                ACCEPTED_FILE_TYPES: "Accepted file types",
                MAX_FILE_SIZE: "Max file size up to {0} MB",
                FILE_SIZE: "Dimensiune fișier",
                FILE_TYPE: "Tip fișier",
                MAX_FILES: "Număr maxim de fișiere",
                ADVANCED_CONDITIONS: "Advanced conditions",
                CONDITION_EXPRESSION: "Condition expression",
                CONDITION_FIELD: "Field",
                CONDITION_OPERATION: "Operation",
                CONDITION_VALUE: "Value",
                AUTOCOMPLETE_FORM: "Autocomplete form",
                LOAD_ALL_GUEST_APPLICATIONS: "Toate actele emise indiferent de depunator",
                LOAD_ALL_ORG_APPLICATIONS: "Toate actele emise indiferent de organizatie",
                LOAD_EXTERNAL_DOCUMENTS: "Incarcă documentele externe",
                DOCUMENT_KEY: "Document key",
                CUSTOM_NOTFOUND_MESSAGE: "Mesaj de eroare personalizat",
                COEFFICIENTS: "Coefficienți",
                UAT: "UAT",
                VILLAGE: "Localitate",
                COEFF_VALUE: "Coefficient value",
                AC_TAX_CATEGORIES: "Taxa per sistem constructiv",
                AC_TAX_A: 'Sistem constructiv A(beton)',
                AC_TAX_B: 'Sistem constructiv B(lemn, piatra)',
                AC_TAX_C: 'Sistem constructiv C(anexe)',
                AC_TAX_OTHER: 'Altele',
                RU_SCOPE: 'Scop Ru',
                UTR_PDF_DOCUMENT_TEMPLATE: 'Formularul tipizat folosit la generearea PDFului din UTR',
            },
        },
        UPLOAD: {
            PREVIEW: "preview",
            EDIT: "replace",
            DELETE: "delete",
        },
    },
    APPLICATION_TYPE: {
        LIST: {
            TITLE: "Tipologii de cereri",
        },
        ADD_BUTTON: "Tipologie nouă",
        FIELDS: {
            NAME: "Denumire",
            STATUS: "Status",
            DESCRIPTION: "Descriere",
            FORM_CITIZEN: "Interfață formular cetățean",
            FORM_STAFF: "Interfață formular funcționar",
            REJECT_FORM_STAFF: "Interfață formular respingere funcționar",
            DOCUMENT_TEMPLATE: "Formular tipizat funcționar",
            REJECT_DOCUMENT_TEMPLATE: "Formular tipizat respingere funcționar",
            REVIEW_DOCUMENT_TEMPLATE: "Formular tipizat clarificări cetățean",
            PUBLIC_DOCUMENT_TEMPLATE: "Formular tipizat document public",
            APPLICATION_DOCUMENT_TEMPLATE: "Formular tipizat cetățean",
            DOCUMENT_SERIES_TYPE: "Tip serie document funcționar",
            APPLICATION_DOCUMENT_SERIES_TYPE: "Tip serie document cetățean",
            TAX_CATEGORY: "Categorie de taxare",
            CONSTRUCTION_TAX_CATEGORY: "Categorie de taxare 1 (metadata construction_value)",
            LAND_TAX_CATEGORY: "Categorie de taxare 2 (metadata land_value)",
            EXTRA_TAX_CATEGORY: "Categorie de taxare 3 (altă taxă aplicată pe valoare)",
            APPLICATION_TYPE_REF: "Documente referință",
            ARCHIVE_DOCUMENT_TYPE_CODE: "Tip document arhivă",
            WORKFLOW: "Proces",
            TENANT_ID: "Organizație",
            IS_AUTO: "Tipologie automată",
            REMAINING_PERIOD: "Timp rămas",
            ORDER: "Prioritate in listă (ordine)",
            DISCOUNTS: "Discount-uri disponibile",
            UATS: "UAT-uri disponibile",
            IS_INTERNAL: "Document intern",
            HAS_DIGITAL_SEAL: "Aplică sigiliu pe actul emis",
            REVIEW_APPLICATION_TYPE_ID: "Nota de clarificări aferentă",
            INTERNAL_APPLICATION_TYPE_ID: "Document intern aferent",
            DEPARTMENTS: "Departamente",
            FUNCTIONS: "Funcții"
        },
        FORM: {
            EDIT_TITLE: "Editează tip cerere",
            ADD_TITLE: "Tip cerere nouă",
        },
        ENUMS: {
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
        },
        PLACEHOLDERS: {
            NAME: "Caută după denumire",
            STATUS: "Status",
            NO_REF_DOC: "Nici un document de referință",
            NO_DISCOUNTS: "Nici un discount",
            APPLY_DISCOUNT: "Aplică discount pentru UAT rural",
            IS_TAX_FIXED: "Taxă per cerere (fără amplasamente)",
            IS_TAX_CATEGORY_CONDITIONAL: "Taxă condiționată de valoare",
            NO_UATS: "Nici un UAT",
        },
        MESSAGES: {
            IS_AUTO: "O tipologie automată nu are documente de referință",
        },
    },
    DOCUMENT_TEMPLATE: {
        LIST: {
            TITLE: "Formulare tipizate",
            ADD_BUTTON: "Formular nou",
            TABLE_HEADER: "Modele formulare",
        },
        FIELDS: {
            NAME: "Denumire",
            DESCRIPTION: "Descriere",
            STATUS: "Stare",
            CREATED_DATE: "Data inserării",
        },
        PLACEHOLDERS: {
            STATUS: "Stare",
            NAME: "Nume",
        },
        ENUMS: {
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
        },
        CREATE_MODAL: {
            TITLE: "Salvare formular tipizat",
        },
        EDIT_MODAL: {
            TITLE: "Editare formular tipizat",
        },
        FORM: {
            TITLE: "Template formular",
            DOWNLOAD_BUTTON: "Descarcă",
            FIELDS_LIST_TITLE: "Inserează câmpuri",
            APPLICATION_TYPES_LABEL: "Selectează tipul de cerere",
            RELATED_APPLICATION_TYPES_LABEL:
                "Selectează tipul de cerere asociat",
            FORM_TYPE_LABEL: "Selectează tipul de formular",
            CITIZEN_LABEL: "Cetățean",
            STAFF_LABEL: "Funcționar",
            SELECT_TYPE: "Selectează tipologie",
            APPLICATION_TYPE: "Cerere",
            CONTROL_TYPE: "Control",
        },
    },
    PROCESS_EDITOR: {
        HEADER: "Management procese",
        NEW_PROCESS_BTN: "Proces nou",
        GENERAL: {
            HEADER: "Editor de proces",
            HEADER_LIST: "Listă Procese",
            TITLE: "Proces nou",
            SAVE_BTN: "Salvează",
            EDIT_BTN: "Modifică",
            FIELDS: {
                NAME: "Denumire",
                DESCRIPTION: "Descriere",
                STATUS: "Status",
            },
            PLACEHOLDERS: {
                NAME: "Caută după denumire",
                DESCRIPTION: "Descriere",
            },
        },
        TABS: {
            GENERAL: "General",
            STEPS: "Etape",
        },
        STEPS: {
            HEADER: "Editor de proces",
            HEADER_LIST: "Etape create",
            EDIT_MODAL_HEADER: "Modifică etapă",
            ADD_MODAL_HEADER: "Adaugă etapă",
            ADD_BTN: "Adaugă etapă",
            SAVE_LIST_BTN: "Salvează etape",
            PUBLISH_BTN: "Publică",
            PUBLISH_MSG:
                "Etapa de start nu este prima in listă! Lista nu se poate publica.",
            PUBLISH_CONFIRM_MSG:
                "Sunteți sigur ca vreți să publicați acest proces? El va fi disponibil pentru a putea fi folosit",
            FIELDS: {
                NAME: "Nume Etapă",
                ACTION_NAME: "Acțiune",
                ACTION_TYPE: "Tip acțiune",
                NEXT_STEP: "Etapa următoare",
                CONDITIONAL_ROUTING: "Rutare condiționată",
                FILE_STATUS: "Status fișier",
                FILE_TYPE: "Tip fișier",
                FUNCTION_TYPE: "Funcție",
                SIGN_BY_ASSIGNED_USER: "Semnare de către userul asignat",
                APPLICATION_STATUS: "Status cerere",
                RETURN_ACTION: "Revenire la",
            },
            PLACEHOLDERS: {
                NAME: "Nume",
                ACTION_NAME: "Actiune",
                ACTION_TYPE: "Tip acțiune",
                NEXT_STEP: "Etapa următoare",
                CONDITIONAL_ROUTING: "Rutare condiționată",
            },
            ACTIONS: {
                START: "Start",
                END: "Sfârșit",
            },
        },
        ENUMS: {
            STATUS: {
                YES: "DA",
                NO: "NU",
                DRAFT: "Draft",
                ERROR: "Nepublicat",
                PUBLISHED: "Publicat",
                PENDING: "Procesare",
            },
        },
        TABLE_HEADER: {
            TRANSITION: "Tranziție",
            ACTIONS: "Acțiuni",
        },
        MESSAGES: {
            ALREADY_EXISTS: "Un proces cu această denumire există deja",
        },
    },
    PERMISSIONS: {
        HEADER: "Permisiuni",
        FILTER: {
            TITLE: "Listă permisiuni",
        },
        BUTTONS: {
            SET_PERMISSIONS: "Setări Permisiuni",
        },
        FIELDS: {
            NAME: "Nume",
            PERMISSION_NAME: "Denumire",
            DESCRIPTION: "Descriere",
            CATEGORY: "Categorie",
            SCOPE: "Scope",
            ORGANISATIONS: "Organizație",
            SYSTEM_DEFAULT: "Asignare Default",
            DEFAULT_ASSIGNED_TO: "Utilizator Default",
            APPLY_DEFAULT_TO_ORGANISATION: "Organizație Default",
        },
        PLACEHOLDERS: {
            NAME: "Nume",
            PERMISSION_NAME: "Denumire",
            CATEGORY: "Categorie",
        },
        FORM: {
            TITLE: "Setează permisiuni",
            PERMISSIONS: "Permisiuni",
        },
    },
    REAL_ESTATE: {
        FORM: {
            HEADER: "Adaugă amplasament",
            ADD_BTN: "Adaugă amplasament nou",
            ADD_FILE_LABEL: "Adaugă plan cadastral (pdf/zip)",
            ADD_FILE_BTN: "Alege fișiere",
            DUPLICATE_CADASTRAL_NUMBER:
                "Acest număr cadastral este deja adăugat",
            DIFFERENT_AREA: "Aria introdusă este diferită de cea de la ANCPI",
            WRONG_COUNTY: "Se pot depune cereri doar pe județul {county}",
        },
        VIEW: {
            ADDRESS: "Adresă",
            CONTACT: "Contact",
            CADASTRAL_PLAN: "Plan cadastral",
            NO_FILES: "Nu există fișiere",
            FILES_LABEL: "Fișiere de identificare",
        },
        SINGLE: "Total {total} amplasament",
        MULTIPLE: "Total {total} amplasamente",
        REAL_ESTATES: "Amplasamente",
        DOCUMENTS: "Documente",
        LIST_HEADER: "Lista amplasamentelor",
        MODAL: {
            ADD_HEADER: "Adaugă un amplasament",
            SUMMARY_HEADER: "Lista completă a imobilelor",
        },
        DETAILS_MODAL: {
            HEADER: "Detalii amplasament (data integrogare ANCPI: {date})",
            SUBHEADER_DESCRIPTION: "A. Partea I. Descrierea imobilului",
            SUBHEADER_REGISTRATIONS_FIRST: "B. Partea II. Proprietari si acte",
            SUBHEADER_REGISTRATIONS_SECOND: "C. Partea III. SARCINI",
            NO_DATA: "Nu există date",
            GENERAL: {
                HEADER: "Date generale",
                UAT: "UAT",
                CADASTRAL_NUMBER: "Nr. cadastral",
                PREVIOUS_CAD_NO: "Nr. cadastral vechi",
                PREVIOUS_LAND_BOOK_NO: "Nr. CF vechi",
                MEASUREAD_AREA: "Suprafața măsurată",
                LEGAL_AREA: "Suprafața legală",
            },
            ADDRESS: {
                HEADER:  "Adresă",
                COUNTY: "Județ",
                ADMIN_UNIT: "UAT",
                LOCALITY: "Localitate",
                STREET_TYPE: "Tip stradă",
                STREET_NAME: "Stradă",
                POSTAL_NUMBER: "Nr. poștal",
                BUILDING_NUMBER: "Nr. clădire",
                BUILDING_PART_NUMBER: "Nr. clădire (parte)",
                FLOOR_NUMBER: "Etaj",
                BUILDING_UNIT_NUMBER: "Nr. unitate",
                POSTAL_CODE: "Cod poștal",
            },
            PARCELS: {
                TITLE: 'Parcele',
                HEADER: "Parcelă {number}",
                MEASUREAD_AREA: "Suprafața măsurată",
                LEGAL_AREA: "Suprafața legală",
                PROPERTY_TITLE_NO: "Titlu proprietate",
                USE_CATEGORY: "Utilizare",
                IS_AGRICULTURAL: "Agricol",
                INTRAVILAN: "Intravilan",
            },
            BUILDINGS: {
                TITLE: 'Construcții',
                HEADER: "Clădire {number}",
                MEASUREAD_AREA: "Suprafața măsurată",
                LEGAL_AREA: "Suprafața legală",
                USE_CATEGORY: "Utilizare",
                LEGAL: "Legal",
            },
            OWNERS: {
                HEADER: "Proprietari",
                NAME: "Nume",
                CNP: "CNP",
                OWNERSHIP_TYPE: "Tip proprietate",
                IS_PART_HOLDER: "Deținătorul unei părți",
                DESCRIPTION: "{registration_type}, drept de {right_type}, dobândit prin {property_transfer_type}, cotă actuală {actual_quota}."
            },
            REGISTRATIONS: {
                HEADER_FIRST: 'Înscrieri privitoare la dreptul de proprietate şi alte drepturi reale',
                HEADER_SECOND: 'Înscrieri privind dezmembrămintele dreptului de proprietate, drepturi reale de garanţie şi sarcini',
            }
        },
        CARD: {
            CADASTRAL_NUMBER: "Nr. cadastral",
            UAT: "UAT",
            LAND_AREA: "Suprafață",
            ADDRESS_COUNTY: "Județul {county}",
            CATEGORY: "Cod categorie",
            MISSING_COORDINATES_MESSAGE: "Lipsă coordonate ANCPI",
        },
    },
    METADATA_MAPPING: {
        FIELDS: {
            METADATA: "Metadata GU",
            ARCHIVE_METADATA_NAME: "Metadata Arhivă Nume",
            ARCHIVE_METADATA_LABEL: "Metadata Arhivă Etichetă",
        },
        PAGE_TITLE: "Mapare Metadate Arhiva cu Metadate GU",
        TABLE_TITLE: "Lista mapărilor existente",
        FORM: {
            ARCHIVE: {
                DOC_TYPE: "Selectează Document",
                METADATA: "Selectează Metadata - Arhivă",
            },
            GU: {
                APP_TYPE: "Selectează tip cerere",
                METADATA: "Selectează Metadata - GU",
            },
        },
        MANDATORY_NOTE:
            "Opțiunile cu prefixul (M) sunt mandatory (obligatorii).",
        CONFIRMATION_MESSAGE:
            "Metadata arhiva <strong>{label}</strong> este asociat la metadata GU <strong>{metadata}</strong>. <hr> Doresti suprascrierea cu <strong>{text}</strong>?",
    },
    ATLAS: {
        MENU: {
            LAYER_MANAGER: "Atlas",
            MY_APPLICATIONS: "Cererile mele",
            INTEREST_ZONES: "Zone de interes",
            DISTANCE: "Distanță",
            ARIA: "Arie",
            SEARCH: "Căutare",
            HELP: "Ajutor",
            VISIBILITY_MANAGER: "Setări vizibilitate",
            PARCEL_SEARCH: "Căutare imobil",
            ARCGIS_APPS: "Aplicatii GIS",
        },
        LAYER_MANAGER: {
            TITLE: "Manager hartă",
            SEARCH_LAYER: "Caută un layer",
            MORE_ACTION: {
                SHARE: "Distribuie",
                EDIT: "Editează",
                EXPORT: "Exportă",
                DELETE: "Șterge",
                PUBLISH: "Publică",
            },
            ATLAS_LABEL: "Atlas",
            APPS_LABEL: "Aplicatii GIS",
            ORGANISATION_LABEL: "Layere organizație",
            PRIVATE_LABEL: "Layerele mele",
            IMPORT_LAYER_LINK: "importă un layer",
            EMPTY_LAYER_TEXT: "Nu există layere",
            EMPTY_ARCGIS_APPS_TEXT: "Nu există aplicații",
            UPLOADING: "Se încarcă...",
            UPLOAD_DONE: "Layerul a fost încărcat cu succes!",
            UPLOAD_ERROR: "Eroare la încărcarea layerului!",
            UPLOAD_MORE: "Doriți să încărcați alt layer?",
            UPLOAD_TRY: "Încercă din nou!",
            DELETE_MODAL_TITLE: "Ești sigur că vrei să ștergi acest layer?",
            DELETE_ARCGIS_APP_MODAL_TITLE: "Ești sigur că vrei să ștergi aceasta aplicație?",
            DELETE_MODAL_SUBTITLE: "Vei renunța la toate datele de pe sublayere.",
            UPLOAD_LAYER: {
                TITLE: "Încarcă acum un layer nou (.ZIP)",
            },
            UPDATE_CONTAINER_TITLE: "Modificare container",
            UPDATE_LAYER_TITLE: "Modifică Layer",
            ADD_LAYER_TITLE: "Adăugare Layer",
            ADD_LAYER_BY_ID_BTN: "Adaugă layer pe baza id-ului",
            TOOLTIP_TEXT: "Layerul este disponibil pe",
            TOOLTIP_URL: "gis.cluj.archi",
            SYSTEM_LAYER: "Layer sistem",
            LAYER_SYNC_SUCCESS: "Layerul a fost actualizat cu succes.",
            LAYER_SYNC_ERROR: "Layerul nu a putut fi actualizat.",
            SHOW_LEGEND: "Arata legendă",
            HIDE_LEGEND: "Ascunde legendă",
            LAYER_TITLE: "Titlu layer",
        },
        APPLICATIONS_MANAGER: {
            TITLE: "Cererile mele",
        },
        INTEREST_AREAS_MANAGER: {
            TITLE: 'Zonele mele de interes',
            TITLE_TOOLTIP: 'Pentru a adăuga o nouă zonă de interes, a se folosi opțiunea de măsurare arie din meniul de pe hartă. Pentru finalizare definire arie, faceți dublu click.',
            AREA: 'Arie',
            NOTIFICATIONS: 'Notificări',
            NOTES: 'Notițe',
            PERIMETER: 'Perimetru',
            ADD_NEW_ZONE_TITLE: 'Adăugare zonă nouă de interes',
            EDIT_NEW_ZONE_TITLE: 'Editare zonă de interes',
            NOTES_PLACEHOLDER: 'Adaugă informație cheie',
            BUTTONS: {
                NEW_AREA: 'Zonă nouă'
            },
            DELETE_CONFIRMATION_TEXT: 'Ești sigur că vrei să ștergi zona de interes?'
        },
        INTERSECTION_MANAGER: {
            TITLE: "Locație marcată",
            INFO_LAYER_TEXT: "Informații",
            CU_INFO_LAYER_TEXT: "Certificat urbanism",
            AUTHORIZATION_INFO_LAYER_TEXT: "Autorizație",
            DETAILS_TEXT: "Detalii",
            SIRUTA_LABEL: "SIRUTA",
            COUNTY_NAME_LABEL: "Denumire județ",
            COUNTY_INDICATIV_LABEL: "Indicativ județ",
            UAT_LABEL: "Denumire UAT",
            APPROVAL_YEAR_LABEL: "An aprobare PUG",
            YEAR_OF_EXTENSION_LABEL: "An prelungire PUG",
            NO_YEAR_OF_EXTENSION: "Nu este cazul",
            URBANISM_REGULATION_LABEL: "Reglementări urbanistice",
            APPLICATION_ISSUED_DATE: "Data emiterii",
            APPLICATION_TYPE: "Tip",
            APPLICATION_STATUS: "Status",
            HELP_TEXT:
                "Nu a fost găsit nici un rezultat, selectează un punct pe hartă!",
            EXPAND_LINK: "expandează >>",
            APPLICATION_STATUSES: {
                DRAFT: "Draft",
                NEW: "Nou",
                EMITTED: "Finalizat",
                REJECTED: "Respins",
            },
        },
        INTERSECTION_DETAILS_MODAL: {
            HEADER: "Informații locație marcată",
            RLU_RT: 'Regim Tehnic',
            RLU_RJ: 'Regim Juridic',
            RLU_RE: 'Regim Economic',
            RLU_POT: 'POT',
            RLU_CUT: 'CUT ',
            RLU_RDA: 'Regim de actualizare',
        },
        SUBLAYER_MANAGER: {
            SUBLAYER_NAME: "Denumire sublayer",
            SUBLAYER_ACTIONS: "Acțiuni",
            EMPTY_SUBLAYERS_TEXT: "Nu există sublayere",
            SCALE_RANGE_SLIDER_TITLE: "Parametri Sublayer",
            SUGGEST_SCALE_RANGE_TEXT: "Sugerați o scală",
            SCALE_RANGE_SLIDER_TEXT: "Scală",
            OPACITY_SLIDER_TEXT: "Opacitate",
            CLUSTERING_CHK_LABEL: "Clustering",
            HEATMAP_CHK_LABEL: "Hartă termografică",
        },
        COMMENTS_MANAGER: {
            COMMENTS: "Comentarii",
            ADD_COMMENT: "Adaugă comentariu",
            DELETE_COMMENT: "Sterge comentariu",
            DELETE_ALL_COMMENTS: "Sterge discutia",
            REPLY_TO_COMMENT: "Răspunde la comentariu",
            EMPTY_COMMENTS_TEXT: "Nu există comentarii",
            INSTRUCTIONS: "Selectează un punct pe hartă prin dublu click",
            CONFIRMATION_MESSAGE_DELETE: "Esti sigur ca vrei sa stergi mesajul?",
            CONFIRMATION_MESSAGE_DELETE_ALL: "Esti sigur ca vrei sa stergi toata discutia?",
        },
        VISIBILITY_MANAGER: {
            TITLE: "Setări vizibilitate",
            ORDER_COLUMN_HEADER: "Ordine",
        },
        CREATE_LAYER_MODAL: {
            HEADER: "Adaugă layer nou",
            ERROR: "Container cu scop RU selectat deja există!",
        },
        PARCEL_SEARCH: {
            SHOW_PARCEL_SEARCH: "Caută imobil",
            HIDE_PARCEL_SEARCH: "Închide căutare imobil",
            COUNTY_PLACEHOLDER: "Alege județ",
            UAT_PLACEHOLDER: "Alege UAT",
            CADASTRAL_NUMBER_PLACEHOLDER: "Introdu numarul carții funciare",
            MODAL: {
                HEADER:'Căutare imobil',
            },
            TITLE: "Căutare imobil",
            SEARCH_BTN: "Caută imobil",
            SEARCH_HISTORY_LABEL: "Istoric de căutare",
        },
        PUBLISH_LAYER_MODAL: {
            HEADER: "Publicare layer",
        },
        ARCGIS_APPS_MODAL: {
            ADD_NEW_APPLICATION: "Adaugă Aplicatie",
            HEADER_EDIT: "Modificare aplicatie GIS",
            HEADER_ADD: "Adaugare aplicatie GIS",
        },
        COMPARE_LAYER_MODAL: {
            HEADER: "Comparare Layere",
            TEMPLATE_LAYER: "Destinație",
            TEMPLATE_LAYER_LABEL: "Structură layer șablon",
            SOURCE_LAYER: "Sursa: {layer}",
            SOURCE_LAYER_LABEL: "Structură layer sursă",
            FORCE_PUBLISH: "Forțează publicarea",
            STATUS: {
                READY: "Layerul este pregătit de publicare.",
                NOT_READY:
                    "Layerul nu poate fi publicat, structura lui nu respectă structura layer-ului șablon.",
            },
        },
        LAYER_DETAILS: {
            TITLE: "Detalii layer {name}",
            GENERAL_INFO: "Informații generale",
            GIS_DETAILS: "Detalii GIS",
            ID: "ID:",
            LAYER_TYPE: "Tip layer:",
            ACCESS_LEVEL: "Nivel acces:",
            CONTAINER_ID: "Container ID:",
            CREATED_DATE: "Created date:",
            RU_SCOPE : "Scop RU:",
            UAT_NAME: "UAT:",
            UAT_ID: "UAT ID:",
            OWNER_ORG_NAME: "Organizație owner:",
            OWNERSHIP_TYPE: "Model ownership:",
            FEATURE_SERVICE_URL: "Feature Service URL:",
            GIS_LAYER_ID: "GIS Layer ID:",
        },
        FIELDS: {
            NAME: "Denumire layer",
            CONTAINER_NAME: "Denumire container",
            ACCESS_LEVEL: "Nivel acces",
            OWNERSHIP_TYPE: "Tip actualizare",
            ORGANIZATION: "Organizație",
            LAYER_TYPE: "Tip layer",
            CONTAINER_LAYER: "Alege container din Atlas",
            RU_SCOPE: "Scop RU",
            UAT: "UAT",
            USE_AS_TEMPLATE: "Adaugă structură pentru toate UAT-urile",
            DECISION: "HCL(prin care a fost promulgat)",
            HAS_DECISION: "Nu se aplică",
            LAYER: "Alege layer din Atlas",
            OWNER: "Owner",
            PUBLISH_NAME: "Nume",
            TEMPLATE_NAME: "Nume",
            GEOMETRY_TYPE: "Tip geometrie",
            STATUS: "Status",
            DECISION_ID: "Alege H.C.L.",
            TEMPLATE_LAYER_ID: "Layer șablon",
            ARCGIS_IMPORTED_ITEM: "Identificator layer GIS",
            ARCGIS_IMPORTED_ITEM_TYPE: "Tip layer GIS",
            TITLE: 'Titlu',
            NOTES: 'Notițe',
            NOTIFICATION_ENABLED: 'Notificări active',
            ARCGIS_IMPORTED_ITEM_IS_LINKED: 'Adăugare fără duplicare',
            PRIVATE_LAYER_OWNERSHIP_TYPE: 'Layer privat vs. layer organizatie',
            FEATURE_SERVICE_URL: 'GIS feature url',
            GIS_LAYER_ID: "Identificator layer GIS",
            APP_ID: "ID Aplicație",
            APP_NAME: "Nume Aplicație",
            APP_URL: "URL Aplicație",
            CAN_ACCESS_CITIZEN: "Vizibil în \"Previzualizare Atlas\" pentru cetățeni",
            CAN_ACCESS_STAFF: "Vizibil în \"Previzualizare Atlas\" pentru funcționari",
            APPLICATION_TYPE: "Tip cerere asociat",
        },
        ENUMS: {
            LAYER_TYPE: {
                CONTAINER: "Container",
                SIMPLE: "Simplu",
            },
            ACCESS_LEVEL: {
                PRIVATE: "Privat",
                PUBLIC: "Public",
                PROTECTED: "Protected",
            },
            OWNERSHIP_TYPE: {
                CENTRALIZED: "Centralizat",
                DISTRIBUTED: "Distribuit",
            },
            RU_SCOPE: {
                PUG: "PUG",
                PUZ: "PUZ",
                PUD: "PUD",
                OTHER: "Altul",
            },
            STATUS: {
                OK: "Ok",
                MISSING: "Lipsește",
                NEW: "Nou",
                COMPLETED: "Complet",
                PENDING: "În procesare"
            },
            ARCGIS_IMPORTED_ITEM_TYPE: {
                FEATURE_SERVER: "Feature Server",
                SHAPEFILE: "Shapefile",
            },
            PRIVATE_LAYER_OWNERSHIP_TYPE: {
                PLOT_PRIVATE: "Layer privat",
                PLOT_ORGANISATION: "Layer organizatie"
            }
        },
        EDIT_MODAL: {
            LAYER_NAME: "Denumire",
            SUBLAYER_NAME: "Zone urbanistice",
            ACCESS_LEVEL: "Nivel acces",
            OWNER: "Organizație",
            RU_SCOPE: "Scop RU",
            UAT: "UAT",
            TYPE: "Tip",
            STATUS: "Status",
            TEMPLATE: "Template",
            UPLOAD_BROWSE_TXT: "Alege fișier",
            UPLOAD_BROWSE_PLACEHOLDER: "Alege un fișier sau lasă-l aici...",
            DUPLICATE_LAYER_NAME: "Numele layerului deja există!",
            SUBLAYERS_LIST: "Lista sublayere",
        },
        BUTTONS: {
            ADD_SIMPLE_LAYER: "Adaugă layer simplu",
            CLOSE_SIMPLE_LAYER: "Închide adăugare layer simplu",
            UPDATE_CONTAINER: "Modifică container",
        },
        PUBLISH_STATUS: {
            PUBLISHED: "Publicat",
            NOT_PUBLISHED: "Nepublicat",
            NEW: "Nou",
            PENDING: "În procesare",
            ERROR: "Eroare",
            COMPLETED: "Publicat",
            MESSAGE_SUCCESS: "Layerul a fost publicat cu succes în Atlas",
            MESSAGE_FAILED:
                "Layerul nu a putut fi publicat în Atlas. Vă rugăm reîncercați",
            MESSAGE_CONFIRMATION:
                "Publicarea durează câteva minute. Poți continua să folosești aplicația în acest timp. Urmarește rezultatul publicarii pe layer dupa ce ai reîncarcat pagina.",
        },
        POPUP_TEMPLATE_INFO_FIELDS: {
            APPLICATION_STATUS: "Status cerere",
            APPLICATION_ID: "Id cerere",
            COLOR: "Culoare",
            CADASTRAL_NUMBER: "Nr. cadastral",
            COMBINED_ID: "Id combinat",
            APPLICATION_TYPE: "Tip cerere",
            APPLICATION_TYPE_NAME: "Nume tip cerere",
            APPLICATION_REQUEST_NUMBER: "Nr. cerere",
            APPLICATION_NUMBER: "Nr. autorizație",
            APPLICATION_REQUEST_DATE: "Dată cerere",
            APPLICATION_ISSUED_DATE: "Dată eliberare",
            REAL_ESTATE_TYPE: "Tip amplasament",
            UAT_ID: "Id UAT",
            COUNTY: 'Județ',
            PLACEHOLDER: 'UAT/Nr. cadastral/cerere'
        }
    },
    DECISION: {
        PAGE_HEADER: "Hotărâri",
        LIST: {
            TITLE: "Listă hotărâri",
            EDIT: "Editează",
            DELETE: "Șterge",
            ADD_EXTENSION: "Adaugă prelungire",
        },
        FIELDS: {
            NAME: "Denumire",
            DESCRIPTION: "Label",
            NUMBER: "Număr",
            ENTRY_DATE: "Data emitere",
            EXPIRATION_DATE: "Data expirare",
            TARGET_ID: "Locație",
            PURPOSE_ID: "Scop",
            COUNTY: "Județ",
            DECISION_ID: "Hotărâre",
            KEY: "Cheie",
        },
        PLACEHOLDERS: {
            NAME: "Denumire",
            TARGET_ID: "Caută după locație",
            PURPOSE_ID: "Caută după scop",
        },
        ADD_EDIT_FORM: {
            HEADER_ADD: "Adaugă hotărâre",
            HEADER_EDIT: "Editează hotărâre",
        },
        SUCCESS: {
            ADD: "Hotărârea a fost adaugată cu succes.",
            UPDATE: "Hotărârea a fost modificată cu succes.",
        },
        BUTTON: {
            ADD_DECISION: "Adaugă hotărâre",
        },
        TOTAL_TEXT:  "Total 0 hotărâri| Total 1 hotărâre | Total {total} hotărâri",
        CARD: {
            EXTENSION_LABEL: "Prelungită cu",
            DECISION_LABEL: "Aprobat prin",
            PURPOSE_LABEL: "Faza",
        },
    },
    DECISION_TARGET: {
        LIST: {
            TITLE: "Listă locații",
        },
        ADD_BUTTON: "Adaugă locație",
        FIELDS: {
            NAME: "Denumire",
        },
        FORM: {
            EDIT_TITLE: "Editează locație",
            ADD_TITLE: "Locație nouă",
        },
        PLACEHOLDERS: {
            NAME: "Caută după denumire",
        },
    },
    DECISION_PURPOSE: {
        LIST: {
            TITLE: "Listă scopuri",
        },
        ADD_BUTTON: "Adaugă scop",
        FIELDS: {
            NAME: "Denumire",
        },
        FORM: {
            EDIT_TITLE: "Editează scop",
            ADD_TITLE: "Scop nou",
        },
        PLACEHOLDERS: {
            NAME: "Caută după denumire",
        },
    },
    DOCUMENT_SERIES_TYPE: {
        TITLE: "Tipologii de serie",
        LIST: {
            TITLE: "Listă tipologii de serie",
        },
        ADD_BUTTON: "Tipologie nouă",
        FIELDS: {
            NAME: "Denumire",
        },
        FORM: {
            EDIT_TITLE: "Editează tipologie de serie",
            ADD_TITLE: "Tipologie de serie nouă",
        },
        PLACEHOLDERS: {
            NAME: "Caută după denumire",
        },
    },
    PAYMENT_REGISTRY: {
        TITLE: "Plăți",
        LIST: {
            TITLE: "Istoric plăți",
        },
        CALCULATE_TAX: "Calculează preț",
        ADD_PAYMENT_ORDER: "Dispoziție plată",
        FIELDS: {
            NAME: "Denumire",
        },
        PLACEHOLDERS: {
            NAME: "Caută după denumire",
        },
        GENERATE_REPORT: "Generare raport"
    },
    TAX_CALCULATOR: {
        TITLE: "Calculator preț",
        FORM_SECTION_TITLE: "Taxă eliberare act",
        SUMMARY_SECTION_TITLE: "Sumar comandă",
        FIELDS: {
            APPLICATION_TYPE: "Tipul actului",
            EXEMPTION: "Scutiri taxe conform Cod Fiscal",
            LAND_AREA: "Suprafața terenului",
            TERRITORIAL_ADMINISTRATIVE_UNIT: "UAT",
        },
        CALCULATE_BUTTON: "Calculează",
        SUMMARY: {
            OTHER_TAXES_LABEL: "Alte taxe",
            EXEMPTION_LABEL: "Scutiri",
            VAT_LABEL: "TVA",
        },
        TOTAL: "Total de plată",
        DEFAULT_TAX_NAME: "Act",
    },
    ISSUED_DOCUMENT: {
        TITLE: "Acte emise",
        LIST: {
            TITLE: "Emiterile mele",
            TITLE_STAFF: "Registru general al actelor emise",
        },
        FIELDS: {
            DOCUMENT_SERIES: "Nr.ieșire",
            DOCUMENT_NAME: "Tip act",
            ISSUED_DATE: "Data emiterii",
            BENEFICIARY_USER: "Beneficiar",
            ISSUED_YEAR: "Anul",
            ISSUER_USER_FULL_NAME: "Emis de",
        },
        PLACEHOLDERS: {
            NAME: "Caută după denumire",
            DOCUMENT_NAME: "Tip act",
            ISSUED_YEAR: "Anul",
            BENEFICIARY_USER: "Caută după nume",
            DOCUMENT_SERIES: "Caută după număr",
            ISSUER_USER_FULL_NAME: "Emis de",
        },
    },
    CONTROL: {
        PAGE_HEADER: "Registru control",
        CURRENT_LOCATION: "Locație curentă",
        LIST: {
            TITLE: "Controale",
            DOCUMENT_CREATED_HEADER: "Documente create",
        },
        BUTTON: {
            ADD_CONTROL: "Control nou",
            ADD_COMPLAIN: "Adaugă sesizare",
            ADD_LOCATION: "Adaugă locație",
            NEXT_STEP: "Pasul următor",
            DELETE_LOCATION: "Șterge locație",
            CLOSE_CONTROL: "Clasare",
            GENERATE_PVCC: "Generează PVCC",
            ADD_DOCUMENT: "Document",
            SAVE: "Salvează",
            PREVIEW: "Previzualizează"
        },
        FIELDS: {
            CREATED_DATE: "Data",
            CONTROL_ID: "Cod Intrare",
            COMPLAIN_REF: "Ref Sesizare",
            CREATED_BY: "Creat de",
            STATUS: "Stare",
            COMPLAIN_NUMBER: "Nr. sesizare",
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: "UAT",
            TERRITORIAL_ADMINISTRATIVE_UNIT: "UAT",
            ADDRESS: "Adresă",
            TERRITORIAL_ADMINISTRATIVE_UNIT_TYPE: "Tip UAT",
            STATUS: "Status",
            LOCALITY: "Municipiu / Oraș / Comună",
            FIRST_NAME: "Prenume",
            LAST_NAME: "Nume",
            EMAIL: "E-mail",
            PHONE: "Telefon",
            IDENTIFICATION_NUMBER: "Cod intrare",
            CREATED_DATE: "Dată creare",
            CONTROL_DATE: "Dată intrare",
            CADASTRAL_NUMBER: "Număr cadastru",
            USER_TYPE_NAME: "Tip persoană",
            PERSONAL_NUMERIC_CODE: "CNP",
            COUNTY: "Județ",
            VILLAGE: "Localitate",
            SECTOR: "Sector",
            STREET: "Strada",
            STREET_NUMBER: "Număr stradă",
            POSTAL_CODE: "Cod poștal",
            STAIRCASE: "Scara",
            BLOCK: "Bloc",
            FLOOR: "Etaj",
            APARTMENT: "Apartament",
            REAL_ESTATE_TYPE: "Tip amplasament",
            AUTHORISATION_CODE: "TNA",
            SUBSIDIARY: "Filiala",
            LAND_AREA: "Suprafața terenului",
            OTHER_IDENTIFICATION: "Altă identificare",
            CREATED_BY: "Creat de",
            FILE_TYPE: "Tip document",
            RESOLVED_DATE: "Data soluționării",
            PASSPORT_NUMBER: "Nr. pașaport",
            CITY: "Oraș",
            ADDRESS: "Adresă",
            LICENSE: "Licență",
            COMPANY_NAME: "Companie",
            UNIQUE_REGISTRATION_CODE: "CUI",
            FILENAME: "Nume document",
            TITLE: "Titlu",
            MESSAGE: "Mesaj",
            COMMENTS: "Comentarii",
            ALLOCATED_DOCUMENT_SERIES: "Cod ieșire",
            EMPLACEMENT_LOCALITY: "Localitate",
            OBJECTIONS: "Obiecțiuni contravenient",
            ID_CARD_SERIAL: "Serie CI",
            ID_CARD_NUMBER: "Număr CI",
            ID_CARD_EXPIRATION_DATE: "Data expirării",
            ID_CARD_ISSUER: "Eliberat de",
            CONSTRUCTION_TYPE: "Tip imobil",
            CONTROL_TYPE_NAME: "Tip control",
            ID_CARD_RELEASE_DATE: "Data emiterii",
            REGISTRATION_NUMBER: "Nr. înregistrare",
            PERSON_TYPE: "În calitate de",
            CONTRAVENTION_NUMBER: "Nr tichet",
            CONTRAVENTION_SERIES: "Serie tichet",
            CONTROL_PERSON: "Selectează un martor predefinit"
        },
        PLACEHOLDERS: {
            CONTROL_ID: "Cod Intrare",
            COMPLAIN_REF: "Ref Sesizare",
            CREATED_BY: "Creat de",
            STATUS: "Stare",
            IDENTIFICATION_NUMBER: "Cod intrare",
            CADASTRAL_NUMBER: "Nr CF",
            TERRITORIAL_ADMINISTRATIVE_UNIT: "UAT",
            STATUS: "Status",
            COUNTY: "Județ",
            LOCALITY: "Localitate",
            VILLAGE: "Localitate",
            TERRITORIAL_ADMINISTRATIVE_UNIT_TYPE: "Tip UAT",
            CREATED_BY: "Creat de",
            EMPLACEMENT_LOCALITY: "Localitate",
            COMMENTS: "Comentarii",
            MESSAGE: "Mesaj",
            EMAIL: "E-mail",
        },
        ENUMS: {
            STATUS: {
                REGISTERED: "Înregistrat",
                RESOLVED: "Soluţionat",
                CLOSED: "Clasat",
                GENERATED: "Generat",
                ERROR: "Eroare",
                DRAFT: "Draft",
            },

        },
        LABEL: {
            CONTROL_ID: "Numar registru",
        },
        FORM: {
            ADD_TITLE: "Adaugă control",
            EDIT_TITLE: "Control nr. {0}",
            NEW_CONTROL: "Control nou",
        },
        PREPARATION: {
            HEADER: "Detalii sesizare",
            COMPLAIN_LIST_TAGS: "Listă cod intrare sesizări",
            WARNING:
                "Pentru a adăuga un control adaugă o locaţie sau selectează din lista de sesizări !",
            LIST: "Listă locaţii",
            PREVIEW_MAP: "Previzualizare din atlas teritorial",
            FORM: {
                LOCATION: "Locaţie",
                COMPLAIN_DESCRIPTION: "Conform sesizare",
                MODAL_HEADER: "Date locaţie sesizare",
            },
            SEARCH_FOR: "sesizări"
        },
        MODALS: {
            ADD: "Adaugă",
            CANCEL: "Renunță",
            SAVE: "Salvează",
        },
        NOTIFIERS: {
            DUPLICATE_OFFENDER_PNC: "Contravenientul cu același CNP ({pnc}) este deja adăugat.",
            DUPLICATE_WITNESS_PNC: "Martorul cu același CNP ({pnc}) este deja adăugat.",
            DUPLICATE_OFFENDER_PASSPORT: "Contravenientul cu același număr de pașaport ({passport}) este deja adăugat.",
            DUPLICATE_WITNESS_PASSPORT: "Martorul cu același număr de pașaport ({passport}) este deja adăugat.",
            CADASTRAL_NUMBER_NOT_FOUND: "Ne pare rău, numărul cadastral nu a fost găsit. Incercați din nou!",
            CADASTRAL_NUMBER_ERROR: "Ne pare rău, sincronizarea nu a fost cu succes. Incercați din nou!",
            DEFAULT_ERROR_MESSAGE: "A apărut o eroare, vă rugăm reîncercați",
            GENERATE_DOCUMENTS_WARN: "Generare PVCC",
            GENERATE_DOCUMENTS_WARN_TEXT: "Vă rugăm să salvați modificările făcute înainte de a genera documentul. Dupa generarea documentelor, controlul nu va mai putea fi modificat. Sunteți sigur că doriți să continuați?",
            GENERATION_COMPLETED: "Generare cu succes!",
            GENERATION_COMPLETED_TEXT: "Documentele generate se pot vizualiza în secțiunea 'Documente'.",
            GENERATION_ERROR: "A apărut o eroare la generare!",
            GENERATION_ERROR_TEXT: "Vă rugăm reîncercați generarea documentelor.",
            CLOSE_CONTROL_SUCCESS: "Controlul a fost clasat cu succes!",
            CLOSE_CONTROL_SUCCESS_TEXT: "În urma clasării, acesta nu mai poate fi modificat.",
            CLOSE_CONTROL_WARN: "Clasare control",
            CLOSE_CONTROL_WARN_TEXT: "Un control clasat nu va mai putea fi modificat. Sunteți sigur că doriți să continuați?",
            PREVIEW_DOCUMENTS_WARN: "Previzualizare PVCC",
            PREVIEW_DOCUMENTS_WARN_TEXT: "Vă rugăm să salvați modificările făcute, înainte de a previzualiza documentul.",

        },
        UPLOAD_DOCUMENTS_MODAL: {
            TITLE: "Adaugă documente"
        },
        TABS: {
            CONTROL_PREPARATION: "Pregătire control",
            DATA_COLLECTION: "Colectare date",
            DOCUMENTS: "Documente",
            ACTIVITIES: "Activități"
        }
    },
    DASHBOARD: {
        APPLICATIONS_STATUS: {
            HEADER: "Status Cereri",
            PERIOD: "Ultimele 30 zile",
        },
        TOP_USERS: {
            HEADER: "Top utilizatori",
        },
        REGISTRED_APPLICATIONS: {
            HEADER: "Cereri înregistrate",
            DESCRIPTION: "Raport cereri depuse vs soluționate",
            PERIOD: "Ultimele 12 luni",
        },
        SOLVED_APPLICATIONS: {
            HEADER: "Cereri soluționate",
            DESCRIPTION: "Procentaj soluționări din totalul depunerilor",
            APPLICATIONS_TYPE_HEADER_LEGAL: "Top tipuri de cereri",
            APPLICATIONS_TYPE_HEADER_STAFF: "Cereri pe UAT",
        },
        RECENT_APPLICATIONS: {
            HEADER: "Cereri recente",
            BUTTON: {
                VIEW_ALL_APPLICATIONS: "Vezi toate cererile",
            },
            MISSING:
                "Momentan nu există nici o cerere depusă. Depune acum o cerere online.",
        },
        RECENT_ISSUED_DOC: {
            HEADER: "Acte emise recent",
            BUTTON: {
                VIEW_ALL_ISSUED_DOC: "Vezi toate emiterile",
            },
        },
        PERFORMANCE: {
            HEADER: "Performanţă",
            ABOVE_AVG: "Peste medie",
            NORMAL: "Normal",
            BELLOW_AVG: "Sub medie",
            RESOLUTION_AVG: "Media",
            OBJECTIVE_HEADER: "Obiectiv termen de soluţionare",
        },
        TOTALS: {
            NEW: "Total noi",
            SOLVED: "Total soluţionate",
            IN_OPERATION: "Total în operare",
            PERCENT: "Procent total",
            TOP_USERS: "Top funcţionari repartizaţi",
        },
        TYPES: {
            STATS: "Statistică",
            NEW: "Noi",
            SOLVED: "Soluţionate",
            IN_OPERATION: "În operare",
            TOTAL_LABEL: "Total",
            DAYS: "Zile",
            SOLUTION_TERM: "Termen soluţionare",
            APPLICATIONS_PER_UATS:
                "Cereri pe Unitatea Administrativ Teritorială",
        },
        RECENT_NOTIFICATIONS: {
            MISSING: "Nu aveţi nici o notificare.",
        },
        FIELDS: {
            START_DATE: "Data start",
            END_DATE: "Data sfârșit",
        },
        REGISTERED_APPLICATIONS: {
            HEADER: "Cereri înregistrate",
            DESCRIPTION: "Raport cereri noi vs soluționate",
        },
    },
    URBANISM_REGULATION_ZONES: {
        LIST: {
            TITLE: "Reglementări urbanistice",
        },
        ADD_BUTTON: "Reglementare nouă",
        FIELDS: {
            NAME: "Denumire",
            KEY: "Cheie",
            TYPE: "Scop RU",
            TENANT: "UAT",
            DISPLAY_NAME: "Denumire atlas",
        },
        FORM: {
            EDIT_TITLE: "Editează reglementare",
            ADD_TITLE: "Reglementare nouă",
        },
        ENUMS: {
            TYPE: {
                PUG: "PUG",
                PUZ: "PUZ",
                PUD: "PUD",
            },
        },
        PLACEHOLDERS: {
            NAME: "Caută după denumire",
            KEY: "Caută după cheie",
            TENANT: "UAT",
            TYPE: "Scop RU",
        },
    },
    URBANISM_REGULATIONS: {
        TITLE: "Editare categorii RLU pentru {0}",
        FIELDS: {
            CONTENT: "Conţinut",
            CONTENT_HTML: "Conținut public - Acesta este conținutul disponibil în Atlas cetățeanului",
        },
        ITEM_DETAILS: "Detalii",
        EMPTY_ITEMS_LABEL: "Nu există categorii atașate",
        EDIT_ITEM_FORM: "Editare {0} {1}",
    },
    URBANISM_REGULATIONS_PICKER: {
        SECTION_TITLE: "Precompletare manuală a reglementărilor urbanistice",
        TITLE: "Precompletare manuală",
        COMPLETE_BUTTON: "Selectează reglementările",
        NO_DATA_FOUND: "Nu există reglementări urbanistice de afișat",
        PREVIEW_HEADER: "Previzualizare",
        SELECT_HEADER: "Selectează zonă",
        SELECT_PLACEHOLDER: "Caută după denumire",
        ENTRY_NOT_DEFINED: "Nu este definit",
        PREVIEW_HELP_TEXT:
            "Selectează o zonă din lista alăturată pentru a previzualiza conținutul",
        COULD_NOT_SUBMIT_MESSAGE:
            "A apărut o eroare, verificați dacă reglementările urbanistice sunt configurate corect.",
        FORCE_UPDATE_LABEL: "Suprascrie datele",
        HELP_TEXT_COMPLETED:
            "Documentul a fost pre-completat pe baza configurărilor din Atlasul Teritorial ",
        HELP_TEXT_NOT_COMPLETED:
            "Documentul nu a putut fi pre-completat automat deoarece nu s-au găsit informaţii în Atlasul Teritorial",
        HELP_TEXT_FORCE_UPDATE:
            "Toate câmpurile vor fi suprascrise cu valori noi",
        HELP_TEXT_NOT_FORCE_UPDATE:
            "Doar câmpurile goale vor fi completate cu valori noi",
    },
    INTERNAL_DOCUMENTS: {
        LIST: {
            TITLE: "Registru documente interne",
            SUB_TITLE: "Listă documente interne",
            NO_ASSIGNEE: "Nealocată",
            DOCUMENTS_HEADER: "Documente",
            SIGN_TITLE: "Registru documente interne de semnat",
            SIGN_DOCUMENT_BULK: "Semnează documente",
        },
        ADD_BUTTON: "Creează document",
        VIEW_APPLICATION_BUTTON: "Vizualizare cerere {number}",
        FIELDS: {
            APPLICATION_TYPE_NAME_COLUMN: "Tip document",
            IDENTIFICATION_NUMBER: "Cod intrare",
            PARENT_APPLICATION_IDENTIFICATION_NUMBER: "Număr cerere",
            CREATED_DATE: "Dată creare",
            APPLICATION_DATE: "Dată finalizare",
            RESPONSE_DUE_IN: "Timp rămas",
            FILE_TYPE: "Tip document",
            ASSIGNED_TO: "Responsabil",
            ALLOCATED_DOCUMENT_SERIES: "Cod ieșire",
            STATUS: "Status",
            CADASTRAL_NUMBER: "Nr. CF",
            APPLICATION_TYPE_NAME: "Tip document intern",
            REVIEW_COMMENT: "Mențiuni",
            APPLICATION_NUMBER: "Număr cerere",
            PIN: "Cod confirmare",
            CREATED_BY: "Creat de",
            FILENAME: "Nume document",
        },
        PLACEHOLDERS: {
            BENEFICIARY: "Beneficiar",
            IDENTIFICATION_NUMBER: "Cod intrare",
            PARENT_APPLICATION_IDENTIFICATION_NUMBER: "Număr cerere",
            APPLICATION_TYPE_NAME: "Tip cerere",
            CREATED_BY: "Creat de",
            ASSIGNED_TO: "Responsabil",
            STATUS: "Status",
            CADASTRAL_NUMBER: "Nr. cadastral",
            ALLOCATED_DOCUMENT_SERIES: "Cod ieșire",
        },
        FORM: {
            NEW_DOCUMENT: "Document intern nou",
            EDIT_TITLE: "Document {0}",
            DOCUMENT_TYPE_HEADER: "",
            CONTINUE_BTN: "Continuă",
            SAVE_BTN: "Salvează",
            SAVE_AS_DRAFT_BUTTON: "Salvează ca draft",
            PREVIEW_DOC: "Previzualizare",
            FIND_APPLICATION_TITLE: "Caută cerere",
            SEARCH_FOR: "cereri",
            ADD_DOCUMENT: "Adaugă document",
            UPLOAD_DOCUMENT_TITLE: "Adaugă document",
            SIGN_DOCUMENT_TITLE: "Semnează document",
            REFRESH_DOCUMENT: "Reîncarcă",
        },
        TABS: {
            APPLICATION_DETAILS: "Detalii cerere",
            DOCUMENT_DETAILS: "Detalii document",
            DOCUMENTS: "Documente",
            ACTIVITIES: "Activități"
        },
        VIEW: {
            SAVE_AS_DRAFT_BUTTON: "Salvează ca draft",
            REASSIGN_STAFF: "Realocare document",
            ASSIGN_STAFF: "Alocare document",
            SEND_RESPONSE: "Trimite răspuns",
            NEXT_STEP: "Pasul următor",
            SIGN_DOCUMENT: "Semnează document",
            ASSIGN_STAFF_MESSAGE: "Ești sigur că vrei să preiei acest document?",
            APPROVE_DOCUMENT: "Aprobă",
            REJECT_DOCUMENT: "Respinge",
            APPROVE_DOCUMENT_MESSAGE: "Ești sigur că vrei să aprobi acest documente?",
            REJECT_DOCUMENT_MESSAGE: "Ești sigur că vrei să respingi acest document?",
            GENERATE_DOCUMENT_MESSAGE: "Documentul a fost generat",
            GENERATE_DOCUMENT_ERROR: "Documentul nu poate fi generat",
            SIGN_DOCUMENT_ERROR: "Documentul nu poate fi semnat",
            SIGN_SUCCESS: "Documentul a fost semnat",
            SEND_REVIEW: "Necesită clarificări",
            RETURN_DOCUMENT: "Regenerare document",
            RETURN_DOCUMENT_MESSAGE: "Sunteți sigur că vreți să returnați acest document? Această acțiune este ireversibilă și presupune regenerarea actului.",
            SAVE_FAILED: "Salvarea nu a reușit",
            GENERATE_DOCUMENT_BTN: "Finalizează document"
        },
        ENUMS: {
            STATUS: {
                NEW: "Nouă",
                PENDING: "În verificare",
                RESOLVED: "Soluționat",
                REJECTED: "Clasată",
                REJECTED_IN_PROGRESS: "În clasare",
                APPROVED: "În lucru",
                DRAFT: "Draft",
                GENERATED: "Generat",
                SIGNED: "Semnat",
                SEALED: "Sigilat",
                SIGN_PENDING: "În semnare",
                CLOSED: "Finalizată",
                REVIEW: "În clarificări",
                ERROR: "Eroare",
            },
        },
        SIGN_CONFIRMATION: {
            INVALID_PIN: "Codul nu este valid, vă rugăm reîncercați",
            PIN_EXPIRED: "Codul a expirat, vă rugăm reîncercați",
            SESSION_EXPIRED: "Sesiunea a expirat, vă rugăm reîncercați",
            ERROR: "A apărut o eroare, vă rugăm reîncercați",
            BULK_FAILED: "Următoarele cereri nu pot fi semnate {0}",
        },
        SIGN_STATUS: {
            TITLE: "Status semnare",
            IDENTIFICATION_NUMBER: "Cod de intrare",
            PARENT_IDENTIFICATION_NUMBER: "Număr cerere",
            SIGNING_BY: "În curs de semnare la",
            LAST_SIGNING_DATE: "Data ultimei semnări",
        },
        NOTIFIERS: {
            ASSIGN_SUCCESS: "Documentul a fost asignat la <strong>{name}</strong> cu succes!",
            INVALID_PROFILE_FOR_SIGNING_TITLE: "Datele din profil nu sunt valide",
            INVALID_PROFILE_FOR_SIGNING_TEXT:
                "Te rugăm să verifici CNP-ul și datele cărții de identitate din secțiunea <b>Profilul meu</b> necesare pentru semnarea online!",
            INVALID_PROFILE_FOR_SIGNING_BUTTON_TEXT: "Mergi la Profilul meu",
            INVALID_STAFF_ENROLLMENT_TITLE: "A apărut o eroare la procesul de semnare",
            INVALID_STAFF_ENROLLMENT_TEXT: "Te rugăm să te înrolezi pentru a putea semna",
            INVALID_STAFF_ENROLLMENT_BUTTON_TEXT: "Mergi la Profilul meu",
        }
    },
    DOCUMENT_SERIES_ALLOCATION: {
        LIST: {
            TITLE: "Documente externe",
        },
        ADD_BUTTON: "Rezervă serie",
        DETAILS_BUTTON: "Detalii serii documente",
        EXPORT_ALLOCATIONS_BUTTON: "Exportă",
        FIELDS: {
            CREATED_BY: "Rezervat de",
            CREATED_DATE: "Data rezervării",
            ALLOCATED_SERIES: "Serie",
            TYPE_NAME: "Tip serie",
            FILE: "Document (PDF)",
            ISSUED_DATE: "Data emitere",
            LOCATION: "Locație",
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: "UAT",
            IDENTIFICATION_NUMBER: "Număr identificare",
            TAX_VALUE: "Taxă (în lei)",
            AVAILABILITY_PERIOD_MONTHS: "Valabilitate (luni)",
            EXECUTION_DURATION: "⁠Durata de execuție",
            EXECUTION_START_DATE: "⁠Data începere lucrari",
            EXTENSION_DATE: "Data prelungire",
            INVESTITION_VALUE: "Valoarea investiției",
            USE_REAL_ESTATE: "Amplasament vs. pin locatie",
        },
        FORM: {
            ADD_TITLE: "Rezervă serie",
            EDIT_TITLE: "Editează serie",
            VIEW_TITLE: "Vizualizare serie rezervată",
            ADD_LOCATION: "Adaugă locație",
            TITLE_TOOLTIP: "Pentru desenarea unei contur, a se folosi opțiunea de măsurare arie din meniul de pe hartă. Pentru a selecta o locatie de pe hartă, faceți dublu click.",
            LOCATION_TYPES: {
                LABEL: "Tip amplasament",
                PINPOINT: "Locație",
                REAL_ESTATE_LIST: "Carte funciara",
            }
        },
        PLACEHOLDERS: {
            CREATED_BY: "Rezervat de",
            CREATED_DATE: "Data rezervării",
            ALLOCATED_SERIES: "Caută dupa serie",
            TYPE_NAME: "Tip serie",
        },
        NOTIFIERS: {
            CREATED_SUCCESS_TITLE: "Rezervat cu succes",
            CREATED_SUCCESS_TEXT:
                "Seria cu numarul <strong>{number}</strong> a fost rezervată cu succes.",
        },
    },
    APPLICATION_ATTACHMENTS: {
        TITLE: "ANEXE",
    },
    RELATED_APPLICATION: {
        TITLE: "Documente de legătură",
        NOT_FOUND: "Documentul nu a fost găsit în sistem.",
        NOT_FOUND_AND_UPLOAD: "Documentul nu a fost găsit în sistem. Va rugăm să încărcați documentul.",
        SEARCH_DESCRIPTION: "Selectați documentul emis prin Ghișeul Unic sau introduceți numărul",
        ISSUED_BY_SYSTEM_DESCRIPTION: "Emis de {tenant} prin Ghișeul Unic",
        ISSUED_BY_DESCRIPTION: "Emis de altă autoritate/sistem",
        FIELDS: {
            ALLOCATED_DOCUMENT_SERIES: "Cod ieșire",
            ISSUED_DATE: "Anul emiterii",
            ISSUED_BY: "Emis de",
            RELATED_DOCUMENT: "Certificat urbanism",
        },
    },
    OFFENDER: {
        DETAILS: {
            HEADER: "Detalii contravenient",
            ADD_BTN: "Adaugă contravenient",
            REMOVE_BTN: "Șterge contravenient",
            PERSON: {
                HEADER: "Tip persoană",
                INDIVIDUAL: "Persoană fizică",
                LEGAL: "Persoană juridică",
            },
            CITIZENSHIP: {
                HEADER: "Cetățenie",
                ROMANIAN: "Cetățean romȃn",
                FOREIGNER: "Cetățean străin",
            },
            FORM: {
                AUTHORIZATION_CODE: "TNA",
                SUBSIDIARY: "Filiala",
            },
            ADDRESS: "Adresă",
            CONTACT: "Contact",
        },
        SINGLE: "Total {total} contravenient",
        MULTIPLE: "Total {total} contravenienți",
        LIST_HEADER: "Lista contravenienților",
        MODAL: {
            ADD_HEADER: "Adaugă un contravenient",
            EDIT_HEADER: "Editează un contravenient",
        },
        CARD: {
            PERSONAL_NUMERIC_CODE: "CNP",
            ADDRESS: "Adresă domiciliu",
            CONTACT: "Contact",
            CITIZENSHIP: "Cetățenie",
            ADDRESS_COUNTY: "Județul",
            ADDRESS_STREET: "Str.",
            ADDRESS_NUMBER: "nr.",
            ADDRESS_STAIRCASE: "scara",
            ADDRESS_APARTMENT: "ap.",
            PASSPORT_NUMBER: "Pașaport"
        },
    },
    WITNESS: {
        DETAILS: {
            HEADER: "Detalii martor",
            ADD_BTN: "Adaugă martor",
            REMOVE_BTN: "Șterge martor",
            CITIZENSHIP: {
                HEADER: "Cetățenie",
                ROMANIAN: "Cetățean romȃn",
                FOREIGNER: "Cetățean străin",
            },
            PERSON: {
                HEADER: "Tip persoană",
                INDIVIDUAL: "Persoană fizică",
                LEGAL: "Persoană juridică",
            },
            FORM: {
                AUTHORIZATION_CODE: "TNA",
                SUBSIDIARY: "Filiala",
            },
            ADDRESS: "Adresă",
            CONTACT: "Contact",
            SAVE_AS_PREDEFINED: "Salvează ca martor predefinit",
        },
        SINGLE: "Total {total} martor",
        MULTIPLE: "Total {total} martor",
        LIST_HEADER: "Lista martorilor",
        MODAL: {
            ADD_HEADER: "Adaugă un martor",
            EDIT_HEADER: "Editează un martor",
        },
        CARD: {
            PERSONAL_NUMERIC_CODE: "CNP",
            ADDRESS: "Adresă domiciliu",
            CONTACT: "Contact",
            CITIZENSHIP: "Cetătenie",
            ADDRESS_COUNTY: "Judetul",
            ADDRESS_STREET: "Str.",
            ADDRESS_NUMBER: "nr.",
            ADDRESS_STAIRCASE: "scara",
            ADDRESS_APARTMENT: "ap.",
            PASSPORT_NUMBER: "Pașaport"
        },
    },
    REALTY: {
        FORM: {
            HEADER: "Adaugă construcție",
            ADD_BTN: "Adaugă construcție nouă",
            DUPLICATE_CADASTRAL_NUMBER: "Acest număr cadastral este deja adăugat",
            DIFFERENT_AREA: "Aria introdusă este diferită de cea de la ANCPI",
            WRONG_COUNTY: "Se pot depune cereri doar pe județul {county}",
        },
        VIEW: {
            ADDRESS: "Adresă",
            CONTACT: "Contact",
            CADASTRAL_PLAN: "Plan cadastral",
            NO_FILES: "Nu există fișiere",
            FILES_LABEL: "Fișiere de identificare",
        },
        SINGLE: "Total {total} construcție",
        MULTIPLE: "Total {total} construcții",
        LIST_HEADER: "Lista construcțiilor",
        MODAL: {
            ADD_HEADER: "Adaugă o construcție",
            SUMMARY_HEADER: "Lista completă a construcțiilor",
        },
        CARD: {
            CADASTRAL_NUMBER: "Nr. cadastral",
            UAT: "UAT",
            LAND_AREA: "Suprafață",
            COUNTY: "Județul {county}",
            CATEGORY: "Cod categorie",
            MISSING_COORDINATES_MESSAGE: "Lipsă coordonate ANCPI",
            ADDRESS: "Strada {street}, număr {no}"
        },
    },
    STAFF: {
        SECTION_TITLE: "Detalii funcționar",
        FIELDS: {
            FUNCTION: "Funcția",
            ORGANISATION: "Organ control",
            FIRST_NAME: "Prenume",
            LAST_NAME: "Nume",
            IDENTIFICATION_NUMBER: "Număr legitimatie control",
            ID_EXPIRATION_DATE: "Data expirării",
            ID_RELEASED_BY: "Eliberat de",
            ADDRESS: "Adresă sediu / șantier control"
        }
    },
    CONTROL_TYPE: {
        HEADER: "Alege tipul de control",
        LIST: {
            TITLE: "Tipologii de controale",
        },
        ADD_BUTTON: "Control nou",
        FIELDS: {
            NAME: "Denumire",
            STATUS: "Status",
            DESCRIPTION: "Descriere",
            FORM: "Interfață formular",
            DOCUMENT_TEMPLATE: "Formular tipizat",
            DOCUMENT_SERIES_TYPE: "Tip serie document",
            OUTPUT_DOCUMENT_SERIES_TYPE: "Tip serie documente PVCC",
            TENANT_ID: "Organizație",
        },
        FORM: {
            EDIT_TITLE: "Editează tip control",
            ADD_TITLE: "Tip control nou",
        },
        ENUMS: {
            STATUS: {
                ACTIVE: "Activ",
                INACTIVE: "Inactiv",
            },
        },
        PLACEHOLDERS: {
            NAME: "Caută după denumire",
            STATUS: "Status",
        },
    },
    AC_TAX_VALIDATOR: {
        FORM: {
            HEADER: "Adaugă construcție",
        },
        FIELDS: {
            TERRITORIAL_ADMINISTRATIVE_UNIT: "UAT",
            VILLAGE: "Localitate",
            STREET: "Strada",
            STREET_NUMBER_SHORT: "Nr.",
            CONSTRUCTION_SCOPE: "In vederea executarii lucrarilor de",
            CONSTRUCTION_TYPE: "Sistem constructiv",
            SURFACE: "Suprafata desfasurata totala a constructiilor",
            CONSTRUCTION_VALUE: "in valoare de (lei) locuinte",
            CONSTRUCTION_PURPOSE: "pentru (obiectiv)",
            COUNTY: "Judet",
        },
        PLACEHOLDERS: {
            TERRITORIAL_ADMINISTRATIVE_UNIT: "UAT",
            VILLAGE: "Localitate",
            STREET: "Strada",
            STREET_NUMBER_SHORT: "Nr.",
            CONSTRUCTION_SCOPE: "Tip constructie",
            CONSTRUCTION_TYPE: "Sistem constructiv",
            SURFACE: "Suprafata",
            CONSTRUCTION_VALUE: "Valoare",
            CONSTRUCTION_PURPOSE: "",
            COUNTY: "",
        },
        ENUMS: {
            CONSTRUCTION_SCOPE: {
                LOCUINTE: "Locuințe si anexele acestora (0.5%)",
                OTHER: "Alte tipuri de lucrări (1%)",
            },
            CONSTRUCTION_TYPE: {
                BETON: "Cladire cu cadre din beton armat sau cu pereti exteriori din caramida arsa sau din orice alte materiale rezultate in urma unui tratament termic si/sau chimic",
                LEMN: "Cladire cu pereti exteriori din lemn, din piatra naturala, din caramida nearsa, din valatuci sau orice alte materiale nesupuse unui tratament termic si/sau chimic",
                ANEXE: "Cladire-anexa indiferent de sistemul constructiv",
            }
        },
        ERRORS: {
            CONSTRUCTION_VALUE_TOO_SMALL: 'Valoarea introdusă este mai mică decat valoarea minimă!',
            SURFACE_WRONG_FORMAT: 'Valoarea introdusă nu e validă',
        }
    },
    LOCALITIES: {
        LIST_HEADER: "Detalii amplasamente",
        FORM: {
            HEADER: "Adaugă un amplasament nou",
            ADD_BTN: "Adaugă amplasament nou",
            WRONG_COUNTY: "Se pot depune cereri doar pe județul {county}",
        },
        VIEW: {
            ADDRESS: "Adresă",
            CONTACT: "Contact",
        },
        SINGLE: "Total {total} amplasamente",
        MULTIPLE: "Total {total} amplasamente",
        MODAL: {
            ADD_HEADER: "Adaugă un amplasament",
            SUMMARY_HEADER: "Lista completă a amplasamentelor",
        },
        CARD: {
            UAT: "Municipiu / Oraș / Comună",
            COUNTY: "Județul {county}",
            VILLAGE: "Localitate",
            ADDRESS: "Adresă domiciliu",
            ADDRESS_COUNTY: "Judetul",
            ADDRESS_STREET: "Str.",
            ADDRESS_NUMBER: "nr.",
            ADDRESS_STAIRCASE: "scara",
            ADDRESS_APARTMENT: "ap.",
        },
        FIELDS: {
            TERRITORIAL_ADMINISTRATIVE_UNIT: "Municipiu / Oraș / Comună",
            VILLAGE: "Localitate",
            STREET: "Strada",
            STREET_NUMBER_SHORT: "Nr.",
            COUNTY: "Judet",
            STAIRCASE: "Scara",
            BLOCK: "Bloc",
            FLOOR: "Etaj",
            APARTMENT: "Apartament",
        },
        PLACEHOLDERS: {
            TERRITORIAL_ADMINISTRATIVE_UNIT: "Municipiu / Oraș / Comună",
            VILLAGE: "Localitate",
            STREET: "Strada",
            STREET_NUMBER_SHORT: "Nr.",
            COUNTY: "",
        },
    },
    BUILDINGS: {
        LIST_HEADER: "Corpuri de clădire",
        FORM: {
            HEADER: "Adaugă corpuri",
            ADD_BTN: "Adaugă corp nou",
            WRONG_COUNTY: "Se pot depune cereri doar pe județul {county}",
        },
        VIEW: {
            ADDRESS: "Adresă",
            CONTACT: "Contact",
        },
        SINGLE: "Total {total} corp",
        MULTIPLE: "Total {total} corpuri",
        MODAL: {
            ADD_HEADER: "Adaugă un corp nou",
            SUMMARY_HEADER: "Lista completă a corpurilor",
        },
        CARD: {
            NAME: "Nume corp",
            HEIGHT: "Înălțimea construcțiilor propuse",
			HEIGHT_SHORT: "Înălțime",
            NR_LEVELS: "Numărul de niveluri",
            CHARACTERISTICS: "Caracteristici constructive și aspect exterior",
			CHARACTERISTICS_SHORT: "Caracteristici",
            CAPACITY: "Capacități funcționale ale construcțiilor proiectate",
			CAPACITY_SHORT: "Capacități",
        },
        FIELDS: {
            NAME: "Denumire corp c-ție (ex: C1 -locuință)",
            HEIGHT: "Înălțimea la cornișă/streașină (m)",
            MAX_HEIGHT: "Înalțimea la coamă/atic (m)",
            EXISTING_LEVELS: "Număr de niveluri existente",
            PROPOSED_LEVELS: "Număr de niveluri propuse",
            CONSTRUCTION_TYPE: "Sistem constructiv (descriere)",
            FOUNDATIONS: "Fundații (tip + descriere)",
            ROOF_TYPE: "Acoperiș (tip + descriere)",
            HEATING_TYPE: "Sistem de încălzire",
            ISOLATION: "Învelitoare",
            EXTERIOR_FINISH: "Finisaje exterioare",
            EXTERIOR_WOODWORK: "Tâmplărie exterioară",
            FUNCTION: "Funcțiune",
            CATEGORY_OF_IMPORTANCE: "Categoria de importanță",
            BUILDING_FUNCTION: "Tip corp c-ție",
            BUILDING_FUNC_EXISTING: "Existent",
            BUILT_AREA: "Suprafață construită (mp)",
            DEVELOPED_AREA: "Suprafață desfășurată (mp)",
            USEFUL_AREA: "Suprafață utilă (mp)",
            LIVING_AREA: "Suprafață locuibilă (mp)",
            PROPOSED_APARTMENTS_NO: "Număr unități locative propuse",
            ONE_ROOM_APARTMENT_NO: "1 cam.",
            TWO_ROOMS_APARTMENT_NO: "2 cam.",
            THREE_ROOMS_APARTMENT_NO: "3 cam.",
            FOUR_ROOMS_APARTMENT_NO: "4 cam.",
            FIVE_ROOMS_APARTMENT_NO: "5 cam.",
            OTHER_ROOMS_APARTMENT_NO: "Altele",
            OTHER_CHARACTERISTICS: "Alte caracteristici ale capacităţilor funcţionale pentru construcţii propuse, necuprinse în categoriile de mai sus",
            CL_HOUSING_TYPES: "Tipuri"
        },
        PLACEHOLDERS: {
            EXTERIOR_FINISH: "tip: tencuiala decorativa \nculoare: RAL ... \nmaterial:",
            EXTERIOR_WOODWORK: "tip: \nculoare: RAL ... \nmaterial: Lemn/PVC",
            ISOLATION: "tip: tigla ceramica \nculoare: RAL ... \nmaterial:",
            PROPOSED_APARTMENTS_NO: "Suma = A+B+C+D+E+F",
            ONE_ROOM_APARTMENT_NO: "A",
            TWO_ROOMS_APARTMENT_NO: "B",
            THREE_ROOMS_APARTMENT_NO: "C",
            FOUR_ROOMS_APARTMENT_NO: "D",
            FIVE_ROOMS_APARTMENT_NO: "E",
            OTHER_ROOMS_APARTMENT_NO: "F",
        },
        ENUMS: {
            BUILDING_FUNCTION: {
                EXISTING: 'Existent',
                PROPOSED: 'Propus'
            },
            BUILDING_FUNC_EXISTING: {
                DISSOLVED: 'Desființat',
                MAINTAINED: 'Menținut'
            },
            FUNCTION: {
                CL: "Locuințe CL",
                CAS: "Construcții Administrative și Social Culturale CAS",
                CIE: "Construcții Industriale și Edilitare CIE",
                CA: "Construcții Anexa CA"
            },
            CATEGORY_OF_IMPORTANCE: {
                A: "Constructii de importanță excepțională (A)",
                B: "Construcții de importanță deosebită (B)",
                C: "Construcții de importanță normală (C)",
                D: "Construcții de importanță redusă (D)",
            },
            CL_HOUSING_TYPES: {
                PERMANENT: "Permanente",
                SEASONAL: "Sezoniere",
                SOCIABLE: "Sociale",
                FOR_RENT: "de închiriat",
                OF_DUTY: "de serviciu ",
                OF_NECESSITY: "de necesitate ",
                OF_INTERVENTION: "de intervenție ",
                OF_PROTOCOL: "de protocol",
            },
        }
    },
    URBANISM_REGULATIONS_EDIT: {
        SYNC_UTR: "Salvează UTR",
        SYNC_UTR_DESC: "Salvează UTR-ul printre reglementările organizației aferente cererii",
        CHECK_EXISTING_UTR: "Verifică simbol UTR",
        CHECK_EXISTING_UTR_DESC: "Verifică daca simbolul UTR exista deja printre reglementarile curente pe UAT-ul selectat",
        UTR_ALREADY_EXISTS: "Simbolul UTR '{0}' există deja in sistem.",
        UTR_KEY_IS_AVAILABLE: "Simbolul UTR '{0}' este disponibilă.",
        OVERWRITE_EXISTING_UTR_WARN: "Exista in sistem un regulament pentru cheia '{0}', regulamentul curent se va salva cu cheia '{1}'. Continuă?",
        LIST_HEADER: "Reglementari",
        FORM: {
            HEADER: "Adaugă UTR",
            ADD_BTN: "Adaugă un UTR nou",
            ADD_BTN_LABEL: "Sau",
        },
        SINGLE: "Total {total} UTR",
        MULTIPLE: "Total {total} UTR-uri",
        MODAL: {
            ADD_HEADER: "Adaugă un regulament",
            EDIT_HEADER: "Modifică regulament",
            VIEW_HEADER: "Vizualizează regulament",
            SUMMARY_HEADER: "Lista regulamentelor locale de urbanism",
            OPTIONS: {
                LABEL: 'Alege un regulament existent sau adaugă un regulament nou',
                LOAD_EXISTING_UTR: "Alege dintre regulamentele existente",
                ADD_NEW_UTR: "Adaugă un UTR nou",
            }
        },
        CARD: {
            UAT: "Municipiu / Oraș / Comună",
            NAME: "Nume",
            SYMBOL: "Simbol",
        },
        SECTION_LABEL: "SECȚIUNEA {0}",
        ARTICLE_LABEL: "Articolul {0}",
        SECTION_1: "CARACTERUL ZONEI",
        SECTION_2: "UTILIZARE FUNCȚIONALĂ",
        SECTION_3: "CONDIȚII DE AMPLASARE, ECHIPARE ȘI CONFORMARE A CONSTRUCȚIILOR",
        SECTION_4: "POSIBILITĂȚI MAXIME DE UTILIZARE A TERENULUI",
        SECTION_EXISTING_UTR: "Detalii UTR",
        FIELDS: {
            COUNTY: "Județ",
            RU_SCOPE: 'Scop Ru',
            RU_SCOPES: 'Scopuri Ru',
            TERRITORIAL_ADMINISTRATIVE_UNIT: "Selecteaza UAT-ul",
            TERRITORIAL_ADMINISTRATIVE_UNIT_ID: "Selecteaza UAT-ul",
            UTR_ID: 'Alege o reglementare existentă',
            UTR: 'Reglementare',
            NAME: "Denumire UTR",
            SYMBOL: "Simbol UTR(de ex. Lv)",
            ARTICLE_1: "Condiționări generale",
            ARTICLE_2: "Servituți",
            ARTICLE_3: "Utilizări permise",
            ARTICLE_4: "Utilizări permise cu condiționări",
            ARTICLE_5: "Utilizări interzise",
            ARTICLE_6: "Caracteristici ale parcelelor",
            ARTICLE_7: "Amplasarea clădirilor față de aliniament",
            ARTICLE_8: "Amplasarea clădirilor față de limitele laterale și posterioare ale parcelelor",
            ARTICLE_9: "Amplasarea clădirilor unele față de altele pe aceeași parcelă",
            ARTICLE_10: "Condiții de acces și circulații",
            ARTICLE_11: "Staționarea autovehiculelor",
            ARTICLE_12: "Înălțimea maximă a clădirilor",
            ARTICLE_13: "Aspectul exterior al clădirilor",
            ARTICLE_14: "Condiții de echipare edilitară",
            ARTICLE_15: "Spații verzi",
            ARTICLE_16: "Împrejmuiri",
            ARTICLE_17: "Procentul maxim de ocupare al terenului (POT)",
            ARTICLE_18: "Coeficientul maxim de utilizare a terenului (CUT)",
            RLU_RT: 'Regim Tehnic',
            RLU_RJ: 'Regim Juridic',
            RLU_RE: 'Regim Economic',
            RLU_POT: 'POT',
            RLU_CUT: 'CUT ',
            RLU_RDA: 'Regim de actualizare',
        },
        PLACEHOLDERS: {},
        ENUMS: {},
    }
};

module.exports = { locale };
